
export const allDayFormatDate = (date: Date) => {
    if (!date) {
      return [];
    }
    const day = date.getUTCDate() < 10 ? 0 + date.getUTCDate() : date.getUTCDate();
    const month = date.getUTCMonth() < 9 ? 0 + (date.getUTCMonth() + 1) : date.getUTCMonth() + 1;
    const year = date.getUTCFullYear();
    const hour = date.getUTCHours() < 10 ? 0 + date.getUTCHours() : date.getUTCHours();
    const minutes = date.getUTCMinutes() < 10 ? 0 + date.getUTCMinutes() : date.getUTCMinutes();
    const seconds = date.getUTCSeconds() < 10 ? 0 + date.getUTCSeconds() : date.getUTCSeconds();
  
    return [year, month, day, hour, minutes, seconds]; // Include seconds if needed
  };
export const formatDate = (date: Date) => {
  if (!date) {
    return [];
  }
  let dateArray = new Array();
  // don't use date.toISOString() here, it will be always one day off (cause of the timezone)
  const day = date.getDate() < 10 ? 0 + date.getDate() : date.getDate();
  const month =
    date.getMonth() < 9 ? 0 + (date.getMonth() + 1) : date.getMonth() + 1;
  const year = date.getFullYear();
  const hour = date.getHours() < 10 ? 0 + date.getHours() : date.getHours();
  const minutes =
    date.getMinutes() < 10 ? 0 + date.getMinutes() : date.getMinutes();
  const seconds =
    date.getSeconds() < 10 ? 0 + date.getSeconds() : date.getSeconds();
  dateArray = [year, month, day, hour, minutes];
  return dateArray;
};
export const download = (filename: any, text: any) => {
  const element = document.createElement('a');
  element.setAttribute(
    'href',
    'data:text/plain;charset=utf-8,' + encodeURIComponent(text)
  );
  element.setAttribute('download', filename);
  element.setAttribute('target', '_blank');
  element.style.display = 'none';
  element.click();
};

export const createEventData = (_event: any) => {
  let event: any;
  const startDate = new Date(_event.start);
  const isAllDayEvent = startDate.getUTCHours() === 0 && startDate.getUTCMinutes() === 0 && startDate.getUTCSeconds() === 0;
  event = {
    start: formatDate(new Date(_event.start)),
    end: (!isAllDayEvent && _event.end.length > 0)
      ? formatDate(new Date(_event.end))
      : (() => {
          const startDate = new Date(_event.start);
          const endDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 23, 59, 59, 999);
          console.log('endDate ', endDate);
  
          return allDayFormatDate(endDate);
        })(),
    title: _event.title,
    description: _event.description ? _event.description : '',
    location: _event.location ? _event.location : ''
  };
  return event;
};

