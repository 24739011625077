<div class="tooltip"
     [ngClass]="['tooltip--'+position, 'tooltip--'+theme, 'tooltip--'+tooltip.type]"
     [class.tooltip--visible]="visible"
     [style.left]="left + 'px'"
     [style.top]="top + 'px'">
  <ng-container *ngIf="tooltip.type === 'timeline'">
    <ng-container *ngIf="tooltip.content.length">
      <div *ngFor="let data of tooltip.content;" class="content-container">
        <div class="content content-title">{{data.title}}</div>
        <div class="content content-date">{{data.date}}</div>
        <div class="separator"></div>
      </div>
    </ng-container>
    <!-- <ng-container *ngIf="tooltip.content.length <= 3">
      <div *ngFor="let data of tooltip.content;" class="content-container">
        <div class="content-title">{{data.title}}</div>
        <div class="content-date">{{data.date}}</div>
        <div class="separator"></div>
      </div>
    </ng-container> -->
  </ng-container>
  <ng-container *ngIf="tooltip.type === 'tiles'">
    <div>{{tooltip.content}}</div>
  </ng-container>
  <ng-container *ngIf="tooltip.type === 'download'">
    <div class="download-content">{{tooltip.content}}</div>
  </ng-container>
</div>