import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/services/user.service';
import { LanguagesService } from 'src/app/services/languages.service';
import { map, Observable, shareReplay } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component';
import { Router } from '@angular/router';
import { ApiHttpService } from '../../services/api-http.service';
import { ApiEndpointsService } from '../../services/api-endpoints.service';
import { BreakPointService } from '../../../shared/services/break-point.service';

interface languageList {
  languageName: string;
  languageCode: string;
}
interface ModalDialogData {
  titleKey: string;
  contentKey: string;
  positiveMessageKey: string;
  negativeMessageKey: string;
}

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  languageList: languageList[] = [];
  name: string = '';
  userLanguage: string = '';
  userId: any;

  profileForm = new FormGroup({
    name: new FormControl(),
    interfaceLanguage: new FormControl(),
    userId: new FormControl()
  });

  constructor(
    public translate: TranslateService,
    private userService: UserService,
    private languageService: LanguagesService,
    private breakpointObserver: BreakpointObserver,
    public dialog: MatDialog,
    private router: Router,
    private apiHttpService: ApiHttpService,
    private apiEndpointsService: ApiEndpointsService,
    public breakPointService: BreakPointService
  ) {
    this.apiHttpService
      .get(this.apiEndpointsService.getSessionUser())
      .subscribe((response) => {
        let sessionUser = JSON.parse(JSON.stringify(response));
        this.userId = sessionUser.data.id;
        this.name = `${sessionUser.data.firstName} ${sessionUser.data.lastName}`;

        this.userLanguage = ['en', 'de'].includes(
          sessionUser.data.state.lang.toLowerCase()
        )
          ? sessionUser.data.state.lang.toLowerCase()
          : 'en';
        this.userService.setUserConfig(
          `${sessionUser.data.firstName} ${sessionUser.data.lastName}`,
          this.userLanguage,
          sessionUser.data.id
        );
        localStorage.setItem(
          'user',
          JSON.stringify({
            name: this.name,
            languageCode: this.userLanguage,
            id: this.userId
          })
        );
        this.translate.use(this.userLanguage);
      });
  }

  getUserDetails() {
    const { name, languageCode, id } = JSON.parse(
      this.userService.getUserConfig()
    );
    this.name = name;
    this.userLanguage = languageCode;
    this.userId = id;
  }

  getLanguageList() {
    this.languageList = this.languageService.getLanguages();
  }

  updateUserDetails() {}

  ngOnInit(): void {
    this.getLanguageList();
  }

  onSubmit() {
    let { userId, interfaceLanguage, name } = this.profileForm.value;
    delete this.profileForm.value.userId;

    localStorage.setItem(
      'user',
      JSON.stringify({
        name: name,
        languageCode: interfaceLanguage,
        id: userId
      })
    );
    this.translate.use(this.profileForm.value.interfaceLanguage);
    this.userService.setUserConfig(name, interfaceLanguage, userId);
  }

  openDialog() {
    let dialogRef = this.dialog.open(DialogComponent, {
      disableClose: true,
      data: <ModalDialogData>{
        titleKey: 'profile.delete-account.title',
        contentKey: 'profile.delete-account.deleteConfirmation',
        positiveMessageKey: 'yesMessage',
        negativeMessageKey: 'cancelMessage'
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === '1') {
        this.router.navigate(['/']);
      }
    });
  }
}
