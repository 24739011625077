import {Component, OnInit} from '@angular/core';
import {TooltipPosition, TooltipTheme} from "./tooltip.enums";

@Component({
  selector: 'tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnInit {

  position: TooltipPosition = TooltipPosition.DEFAULT;
  theme: TooltipTheme = TooltipTheme.DEFAULT;
  tooltip: any;
  left = 0;
  top = 0;
  visible = false;

  constructor() {
  }

  ngOnInit(): void {
    if(this.tooltip.type === 'timeline'){
      // if(this.tooltip.content.length > 3){
        this.position = TooltipPosition.BELOW;
      // }else{
      //   this.position = TooltipPosition.DEFAULT;
      // }
    }

    if(this.tooltip.type === 'download'){
      this.position = TooltipPosition.BELOW;
    }
  }

}
