<div class="grid-container">
  <mat-grid-list cols="24" rowHeight="30px" gutterSize="16px">
    <mat-grid-tile [colspan]="(breakPointService.isHandset$ | async) ? 24 : 16" rowspan="8">
      <mat-card class="dashboard-card profile-card-container">
        <div class="card-container">
          <div class="cardHead">
            <div class="tile"></div>
            <div class="cardHeadLeft">
              {{ 'translations.profile.profile.title' | translate }}
            </div>
          </div>
          <div class="profile-card">
            <form [formGroup]="profileForm" (ngSubmit)="onSubmit()">
              <div class="inputContainer">
                <label for="name">{{
                  'translations.profile.profile.name' | translate
                }}</label>
                <input
                  [(ngModel)]="name"
                  type="text"
                  name="name"
                  formControlName="name"
                  value=""
                />
              </div>
              
              <div class="inputContainer">
                <label for="name">{{
                  'translations.profile.profile.interfaceLanguage' | translate
                }}</label>
                <div class="languageDropdown">
                  <mat-form-field appearance="fill">
                    <mat-select
                      [(ngModel)]="userLanguage"
                      name="interface-language"
                      id="interface-language"
                      formControlName="interfaceLanguage"
                    >
                      <mat-option
                        *ngFor="let lang of languageList"
                        value="{{ lang.languageCode }}"
                      >
                        {{ lang.languageName }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="profile-button">
                <button mat-raised-button color="#8AD4B7">
                  {{ 'translations.profile.profile.save' | translate }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </mat-card>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="(breakPointService.isHandset$ | async) ? 24 : 16" rowspan="10">
      <mat-card class="dashboard-card password-card-container">
        <div class="card-container">
          <div class="cardHead">
            <div class="tile"></div>
            <div class="cardHeadLeft">
              {{ 'translations.profile.password.title' | translate }}
            </div>
          </div>
          <div class="profile-card profile-card-password">
            <form>
              <div class="inputContainer">
                <label for="current-password">{{
                  'translations.profile.password.currentPassword' | translate
                }}</label>
                <input type="password" name="current-password" value="" />
              </div>
              <div class="inputContainer">
                <label for="new-password">{{
                  'translations.profile.password.newPassword' | translate
                }}</label>
                <input type="password" name="new-password" value="" />
              </div>
              <div class="inputContainer">
                <label for="confirm-password">{{
                  'translations.profile.password.confirmPassword' | translate
                }}</label>
                <input type="password" name="confirm-password" value="" />
              </div>
              <div>
                <button mat-raised-button color="#8AD4B7">
                  {{ 'translations.profile.password.changePassword' | translate }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </mat-card>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="(breakPointService.isHandset$ | async) ? 24 : 16" rowspan="4">
      <mat-card class="dashboard-card account-delete-card-container">
        <div class="card-container">
          <div class="cardHead">
            <div class="tile"></div>
            <div class="cardHeadLeft">
              {{ 'translations.profile.delete-account.title' | translate }}
            </div>
          </div>
          <div>
            <div class="profile-card profile-card-warn">
              <div>
                <div>
                  {{
                    'translations.profile.delete-account.afterDeleting'
                      | translate
                  }}
                </div>
                <button mat-raised-button (click)="openDialog()">
                  {{ 'translations.profile.delete-account.title' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </mat-card>
    </mat-grid-tile>
  </mat-grid-list>
</div>
