
<div class="capital-account">
  <div class="container" *ngIf="isVisible$ | async">
    <div class="header">
      <div class="left-group">
        <mat-form-field appearance="fill" class="select-filters select-year">
          <mat-select class="custom-select-field" [(ngModel)]="selectedYear" (selectionChange)="onReportselectSelection($event)" #yearSelect>
            <mat-option *ngFor="let year of yearsList" [value]="year">
              {{ year.viewValue }}
            </mat-option>
          </mat-select>
          <img class="arrow-down" [ngClass]="{ 'open': yearSelect.panelOpen}" src="../../assets/images/arrow-down.svg"
            matSuffix />
        </mat-form-field>
        <mat-form-field appearance="fill" class="select-filters select-quarter">
          <mat-select class="custom-select-field" [(ngModel)]="selectedQuarter" (selectionChange)="onReportselectSelection($event)" #quarterSelect>
            <mat-option *ngFor="let quarter of quarterListOnYearChange" [value]="quarter">
              {{ quarter.viewValue }}
            </mat-option>
          </mat-select>
          <img class="arrow-down" [ngClass]="{ 'open': quarterSelect.panelOpen}" src="../../assets/images/arrow-down.svg"
            matSuffix />
        </mat-form-field>
      </div>
      <div class="right-group">
        <div class="select-wrapper">
          <app-select class="select-wrapper" *ngIf="reportTypes" [items]="reportTypes" [translateText]="true"
            [selectedItem]="selectedReportTypes" (reportSelected)="onReportSelection($event)"></app-select>
        </div>
        <div [tooltip]="getTooltipText()" class="download-bg" (click)="handleOverviewExcelDownload()"
          [ngClass]="{ disabled: !isOverviewDataAvailable }">
          <img class="downloadImg" src="../../../../assets/images/download_button.svg" alt="Download" />
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="isVisible$ | async">
        <ng-container *ngIf="showTableOne == 1">
          <ng-container *ngIf="!isTableDataLoading; else tableLoader" >
            <div class="table-container table1-container" *ngIf="IFRSData">
              <app-table [enableSorting]="false" [tableColumns]="IFRSTableFirstColumn"
                [tableData]="IFRSData" [lastDayOfQuarter]="lastDayOfQuarter"
                [lastDayOfLastQuarter]="lastDayOfLastQuarter" [languageCode]="languageCode" class="overviewTable">
              </app-table>
            </div>
            <div class="table-container" *ngIf="IFRSData1">
              <app-table [enableSorting]="false" [tableColumns]="IFRSTableSecondColumn"
                [tableData]="IFRSData1" [lastDayOfQuarter]="lastDayOfQuarter"
                [lastDayOfLastQuarter]="lastDayOfLastQuarter" [languageCode]="languageCode">
              </app-table>
            </div>
            <div class="table-container" *ngIf="IFRSData2">
              <app-table [enableSorting]="false" [tableColumns]="IFRSTableThirdColumn"
                [tableData]="IFRSData2" [lastDayOfQuarter]="lastDayOfQuarter"
                [lastDayOfLastQuarter]="lastDayOfLastQuarter" [languageCode]="languageCode">
              </app-table>
            </div>
          </ng-container>

          <div class="no-task-wrapper" *ngIf="!IFRSData && !IFRSData1 && !IFRSData2 && !isTableDataLoading">
            <div class="no-topics-container">
              <div class="icon">
                <img src="../../../assets/images/no-tasks-icon.svg" alt="">
              </div>
              <div class="text-area">
                <div class="main">No data available</div>
                <div class="sub-text">Looks like there is no data available for now. Please check again later
                </div>
              </div>
            </div>
          </div>
          <ng-template #tableLoader>
            <div>
              <div class="table-container table1-container">
                <app-table [enableSorting]="false" [tableColumns]="unloadedOverviewColumns" [tableData]="unloadedData"
                  [lastDayOfQuarter]="lastDayOfQuarter" [lastDayOfLastQuarter]="lastDayOfLastQuarter"
                  [languageCode]="languageCode" class="overviewTable">
                </app-table>
              </div>
            </div>
          </ng-template>
        </ng-container>
        <ng-container *ngIf="showTableTwo == 1">
          <div *ngIf="!isTableDataLoading; else tableLoader">
            <div class="table-container  table1-container" *ngIf="kfwCapitalAccount">
              <app-table [enableSorting]="false" [tableColumns]="kfWCapitalAccountFirstColumn"
                [tableData]="kfwCapitalAccount">
              </app-table>
            </div>
            <div class="table-container" *ngIf="kfwCapitalAccount1">
              <app-table [enableSorting]="false"
                [tableColumns]="kfWCapitalAccountSecondColumn" [tableData]="kfwCapitalAccount1">
              </app-table>
            </div>
            <div class="no-task-wrapper" *ngIf="
            !kfwCapitalAccount &&
            !kfwCapitalAccount1 &&
            !isTableDataLoading">
              <div class="no-topics-container">
                <div class="icon">
                  <img src="../../../assets/images/no-tasks-icon.svg" alt="">
                </div>
                <div class="text-area">
                  <div class="main">No data available</div>
                  <div class="sub-text">Looks like there is no data available for now. Please check again later
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ng-template #tableLoader>
            <div>
              <div class="table-container table1-container">
                <app-table [enableSorting]="false" [tableColumns]="unloadedOverviewColumns" [tableData]="unloadedData"
                  [lastDayOfQuarter]="lastDayOfQuarter" [lastDayOfLastQuarter]="lastDayOfLastQuarter"
                  [languageCode]="languageCode" class="overviewTable">
                </app-table>
              </div>
            </div>
          </ng-template>
        </ng-container>
  </ng-container>
</div>

<!-- <div class="capitalAccount">
  <div
    *ngIf="transactionData"
    [ngClass]="hideCapitalAccount ? 'bottom-bar' : ' '"
  ></div>
  <ng-container *ngIf="isVisible$ | async">
    <div class="filters-container" *ngIf="showOverviewFilters">
      <div class="select-container">
        <mat-select
          [(value)]="selectedYear"
          class="dropdown"
          (selectionChange)="onReportselectSelection($event)"
        >
          <mat-option *ngFor="let year of yearsList" [value]="year">
            {{ year.viewValue }}
          </mat-option>
        </mat-select>
        <mat-select
          [(value)]="selectedQuarter"
          class="dropdown"
          (selectionChange)="onReportselectSelection($event)"
        >
          <mat-option
            *ngFor="let quarter of quarterListOnYearChange"
            [value]="quarter"
          >
            {{ quarter.viewValue }}
          </mat-option>
        </mat-select>
      </div>
      <div class="select-wrapper">
        <app-select
          *ngIf="reportTypes"
          [items]="reportTypes"
          [translateText]="true"
          [selectedItem]="selectedReportTypes"
          (reportSelected)="onReportSelection($event)"
        ></app-select>
      </div>
      <div
        [tooltip]="getTooltipText()"
        class="download-bg"
        (click)="handleOverviewExcelDownload()"
        [ngClass]="{ disabled: !isOverviewDataAvailable }"
      >
        <img src="../../../../assets/download-icon.svg" alt="Download" />
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="isVisible$ | async">
    <div
      class="filters-container transactions-filter"
      *ngIf="showTransactionsFilters"
    >
      <div class="transactions-header_left">
        <div class="transactions-daterange">
          <app-datepicker
            #startDatePicker
            (dateSelected)="handleStartDate($event)"
          ></app-datepicker>
          <div class="to">
            {{ 'translations.capital-account.to' | translate }}
          </div>
          <app-datepicker
            #endDatePicker
            (dateSelected)="handleEndDate($event)"
            [startDate]="startDate"
          >
          </app-datepicker>
        </div>
        <div class="transactions-select-wrapper select-container">
          <mat-select
            id="type"
            [(value)]="selectedTypeValue"
            class="dropdown transaction-select"
            (selectionChange)="onReportselectSelection($event)"
          >
            <mat-option *ngFor="let type of types" [value]="type">
              {{
                'translations.dashboard.capital' + '.' + type.viewValue
                  | translate
              }}
            </mat-option>
          </mat-select>
          <mat-select
            id="status"
            [(value)]="selectedStatusValue"
            class="dropdown transaction-select"
            (selectionChange)="onReportselectSelection($event)"
          >
            <mat-option *ngFor="let status of status" [value]="status">
              {{
                'translations.dashboard.capital' + '.' + status.viewValue
                  | translate
              }}
            </mat-option>
          </mat-select>
          <button mat-raised-button class="reset" (click)="resetTransaction()">
            Clear Filter
          </button>
        </div>
      </div>
      <div class="transaction-count">
        <div class="desc">
          {{ totalTransactions }}
          {{ 'translations.capital-account.noOfTransactions' | translate }}
        </div>
        <div
          [tooltip]="getTooltipText()"
          class="download-bg"
          (click)="handleTransactionDownload()"
          [ngClass]="{ disabled: !isTransactionDataAvailable }"
        >
          <img src="../../../../assets/download-icon.svg" alt="Download" />
        </div>
      </div>
    </div>
  </ng-container>

  <mat-tab-group
    *ngIf="transactionData"
    dynamicHeight
    mat-align-tabs="start"
    class="overview-response"
    (selectedTabChange)="tabChanged($event)"
    [selectedIndex]="tabs.indexOf(capitalTab)"
  >
    <mat-tab label="{{ 'translations.capital-account.overview' | translate }}">
      <div class="tab">
        <ng-container *ngIf="isVisible$ | async">
          <div class="filters-container-handset" *ngIf="showOverviewFilters">
            <div class="select-filters">
              <div class="select-container">
                <mat-select
                  [(value)]="selectedYear"
                  class="dropdown"
                  (selectionChange)="onReportselectSelection($event)"
                >
                  <mat-option *ngFor="let year of yearsList" [value]="year">
                    {{ year.viewValue }}
                  </mat-option>
                </mat-select>
                <mat-select
                  [(value)]="selectedQuarter"
                  class="dropdown"
                  (selectionChange)="onReportselectSelection($event)"
                >
                  <mat-option
                    *ngFor="let quarter of quarterListOnYearChange"
                    [value]="quarter"
                  >
                    {{ quarter.viewValue }}
                  </mat-option>
                </mat-select>
              </div>
            </div>
            <div class="filters-handset-right">
              <div class="select-wrapper">
                <app-select
                  *ngIf="reportTypes"
                  [items]="reportTypes"
                  [translateText]="true"
                  [selectedItem]="selectedReportTypes"
                  (reportSelected)="onReportSelection($event)"
                ></app-select>
              </div>
              <div
                [tooltip]="getTooltipText()"
                class="download-bg"
                (click)="handleOverviewExcelDownload()"
                [ngClass]="{ disabled: !isOverviewDataAvailable }"
              >
                <img
                  src="../../../../assets/download-icon.svg"
                  alt="Download"
                />
              </div>
            </div>
          </div>
        </ng-container>

        <div class="overview">
          <div class="overview-body-container">
            <div>
              <ng-container *ngIf="showTableOne == 1">
                <div *ngIf="!isTableDataLoading; else tableLoader">
                  <div class="table-container">
                    <app-table
                      *ngIf="IFRSData"
                      [enableSorting]="false"
                      [tableColumns]="IFRSTableFirstColumn"
                      [tableData]="IFRSData"
                      [lastDayOfQuarter]="lastDayOfQuarter"
                      [lastDayOfLastQuarter]="lastDayOfLastQuarter"
                      [languageCode]="languageCode"
                      class="overviewTable"
                    >
                    </app-table>
                  </div>
                  <div class="table-container">
                    <app-table
                      *ngIf="IFRSData1"
                      [enableSorting]="false"
                      [tableColumns]="IFRSTableSecondColumn"
                      [tableData]="IFRSData1"
                      [lastDayOfQuarter]="lastDayOfQuarter"
                      [lastDayOfLastQuarter]="lastDayOfLastQuarter"
                      [languageCode]="languageCode"
                    >
                    </app-table>
                  </div>
                  <div class="table-container">
                    <app-table
                      *ngIf="IFRSData2"
                      [enableSorting]="false"
                      [tableColumns]="IFRSTableThirdColumn"
                      [tableData]="IFRSData2"
                      [lastDayOfQuarter]="lastDayOfQuarter"
                      [lastDayOfLastQuarter]="lastDayOfLastQuarter"
                      [languageCode]="languageCode"
                    >
                    </app-table>
                  </div>
                </div>
                <div
                  class="noRecord"
                  *ngIf="
                    !IFRSData && !IFRSData1 && !IFRSData2 && !isTableDataLoading
                  "
                >
                  No records found
                </div>
                <ng-template #tableLoader>
                  <div>
                    <div class="table-container">
                      <app-table
                        [enableSorting]="false"
                        [tableColumns]="unloadedOverviewColumns"
                        [tableData]="unloadedData"
                        [lastDayOfQuarter]="lastDayOfQuarter"
                        [lastDayOfLastQuarter]="lastDayOfLastQuarter"
                        [languageCode]="languageCode"
                        class="overviewTable"
                      >
                      </app-table>
                    </div>
                  </div>
                </ng-template>
              </ng-container>

              <ng-container *ngIf="showTableTwo == 1">
                <div *ngIf="!isTableDataLoading; else tableLoader">
                  <div class="table-container">
                    <app-table
                      *ngIf="kfwCapitalAccount"
                      [enableSorting]="false"
                      [tableColumns]="kfWCapitalAccountFirstColumn"
                      [tableData]="kfwCapitalAccount"
                    >
                    </app-table>
                  </div>
                  <div class="table-container">
                    <app-table
                      *ngIf="kfwCapitalAccount1"
                      [enableSorting]="false"
                      [tableColumns]="kfWCapitalAccountSecondColumn"
                      [tableData]="kfwCapitalAccount1"
                    >
                    </app-table>
                  </div>
                  <div
                    class="noRecord"
                    *ngIf="
                      !kfwCapitalAccount &&
                      !kfwCapitalAccount1 &&
                      !isTableDataLoading
                    "
                  >
                    No records found
                  </div>
                </div>

                <ng-template #tableLoader>
                  <div>
                    <div class="table-container">
                      <app-table
                        [enableSorting]="false"
                        [tableColumns]="unloadedOverviewColumns"
                        [tableData]="unloadedData"
                        [lastDayOfQuarter]="lastDayOfQuarter"
                        [lastDayOfLastQuarter]="lastDayOfLastQuarter"
                        [languageCode]="languageCode"
                        class="overviewTable"
                      >
                      </app-table>
                    </div>
                  </div>
                </ng-template>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab
      *ngIf="hideCapitalAccount"
      label="{{ 'translations.capital-account.transactions' | translate }}"
    >
      <div class="tab transactions-tab">
        <mat-tab-group
          dynamicHeight
          mat-align-tabs="start"
          class="invisible-headers"
          #transactionTab
        >
          <mat-tab label="Transactions List">
            <ng-container *ngIf="isVisible$ | async">
              <div
                class="filters-container-handset transactions-filter-handset"
                *ngIf="showTransactionsFilters"
              >
                <div class="transactions-header_left">
                  <div class="transactions-daterange">
                    <app-datepicker
                      #startDatePicker
                      (dateSelected)="handleStartDate($event)"
                    ></app-datepicker>
                    <div class="to">
                      {{ 'translations.capital-account.to' | translate }}
                    </div>
                    <app-datepicker
                      #endDatePicker
                      (dateSelected)="handleEndDate($event)"
                      [startDate]="startDate"
                    >
                    </app-datepicker>
                  </div>
                  <div class="transactions-select-wrapper select-container">
                    <mat-select
                      id="type"
                      [(value)]="selectedTypeValue"
                      class="dropdown transaction-select"
                      (selectionChange)="onReportselectSelection($event)"
                    >
                      <mat-option *ngFor="let type of types" [value]="type">
                        {{
                          'translations.dashboard.capital' +
                            '.' +
                            type.viewValue | translate
                        }}
                      </mat-option>
                    </mat-select>
                    <div class="space-between"></div>
                    <mat-select
                      id="status"
                      [(value)]="selectedStatusValue"
                      class="dropdown transaction-select"
                      (selectionChange)="onReportselectSelection($event)"
                    >
                      <mat-option
                        *ngFor="let status of status"
                        [value]="status"
                      >
                        {{
                          'translations.dashboard.capital' +
                            '.' +
                            status.viewValue | translate
                        }}
                      </mat-option>
                    </mat-select>
                  </div>
                </div>
                <div class="transaction-count">
                  <button
                    mat-raised-button
                    class="reset"
                    (click)="resetTransaction()"
                  >
                    Clear Filter
                  </button>
                  <div class="download-wrapper">
                    <div class="desc">
                      {{ totalTransactions }}
                      {{
                        'translations.capital-account.noOfTransactions'
                          | translate
                      }}
                    </div>
                    <div
                      [tooltip]="getTooltipText()"
                      class="download-bg"
                      (click)="handleTransactionDownload()"
                      [ngClass]="{ disabled: !isTransactionDataAvailable }"
                    >
                      <img
                        src="../../../../assets/download-icon.svg"
                        alt="Download"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <div class="transactions" *ngIf="transactionData">
              <div class="transaction-table-container">
                <div *ngIf="!isTableDataLoading; else tableLoader">
                  <div class="transaction-table">
                    <ng-container *ngIf="!isLoading">
                      <app-table
                        *ngIf="transactionData.length"
                        [menuTitle]="menuTitle"
                        [enableSorting]="true"
                        (rowClicked)="handleRowClick($event)"
                        [isClickable]="true"
                        [tableColumns]="transactionColumns"
                        [tableData]="transactionData"
                      ></app-table>

                      <div
                        class="noRecord noRecord-transactions"
                        *ngIf="
                          transactionData &&
                          transactionData.length === 0 &&
                          !isTableDataLoading
                        "
                      >
                        No records found
                      </div>
                    </ng-container>
                  </div>
                </div>
                <ng-template #tableLoader>
                  <div class="transaction-table noHover">
                    <div class="table-container">
                      <app-table
                        [menuTitle]="menuTitle"
                        [enableSorting]="true"
                        [tableColumns]="transactionColumns"
                        [tableData]="unloadedTransactionsData"
                      ></app-table>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="View Transaction">
            <div class="view-transactions-container">
              <div class="back-button">
                <button mat-raised-button (click)="backNavigation()">
                  <svg
                    width="9"
                    height="14"
                    viewBox="0 0 9 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      class="primary"
                      d="M3.06367 6.89433L8.42617 12.2568L6.89433 13.7887L0 6.89433L6.89433 0L8.42617 1.53183L3.06367 6.89433Z"
                      fill="#00B886"
                    />
                  </svg>
                  <span class="text">Back</span>
                </button>
              </div>
              <div class="current-transaction-container">
                <div class="view-transaction-table">
                  <ng-container
                    *ngIf="
                      !isSelectedTransactionLoading;
                      else viewTransactionLoader
                    "
                  >
                    <app-table
                      *ngIf="selectedTransaction.length"
                      menuTitle="capital-account"
                      [tableColumns]="viewTransactionColumns"
                      [tableData]="selectedTransaction"
                    ></app-table>
                  </ng-container>
                  <ng-template #viewTransactionLoader>
                    <app-table
                      menuTitle="capital-account"
                      [tableColumns]="viewTransactionColumns"
                      [tableData]="unloadedSingleTransaction"
                    ></app-table>
                  </ng-template>
                </div>
                <div
                  [ngClass]="
                    !isLoading && filePath
                      ? 'pdf-viewer-container'
                      : 'loader-container'
                  "
                >
                  <ng-container *ngIf="isVisible$ | async">
                    <app-pdf-viewer
                      *ngIf="!isLoading && filePath"
                      [filePath]="filePath"
                      [fileName]="documentName"
                      [pageNumber]="1"
                    ></app-pdf-viewer>
                  </ng-container>
                  <ngx-skeleton-loader
                    *ngIf="isLoading"
                    count="1"
                    appearance="line"
                    [theme]="{
                      width: '100%',
                      height: '100%',
                      margin: '0px'
                    }"
                  ></ngx-skeleton-loader>
                  <div
                    class="previewImgContainer"
                    *ngIf="!isLoading && !filePath"
                  >
                    <div class="PDFIconContainer">
                      <svg
                        width="70"
                        height="76"
                        viewBox="0 0 70 76"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M32.6169 60.1776L31.0214 62.2641L32.8787 64.1213C34.4223 65.665 36.7128 67.8307 39.3984 69.6212C42.0387 71.3813 45.3854 73 49 73C50.7069 73 52.3661 72.4621 53.8076 71.7874C55.275 71.1007 56.7035 70.1834 57.9997 69.2221C60.5862 67.3036 62.8882 65.0276 64.2495 63.4849L65.7984 61.7295L64.4721 59.8004C63.3824 58.2153 61.403 55.9644 58.8376 54.0815C56.2873 52.2097 52.8789 50.5 49 50.5C45.1006 50.5 41.5255 52.3129 38.8188 54.2463C36.0714 56.2087 33.8613 58.5503 32.6169 60.1776Z"
                          stroke="white"
                          stroke-width="6"
                        />
                        <circle cx="48.5" cy="62" r="5.5" fill="white" />
                        <path
                          d="M27.5099 73H10.5099C8.00993 72.8333 3.00993 70.3 3.00993 61.5C3.00993 52.7 3.00993 24.8333 3.00993 12C2.84326 9 4.70993 3 13.5099 3C22.3099 3 40.8432 3 49.0098 3C51.3431 3.16667 56.0098 5.2 56.0098 12C56.0098 18.8 56.0098 34.8333 56.0098 42"
                          stroke="white"
                          stroke-width="6"
                          stroke-linecap="round"
                        />
                        <path
                          d="M11.0078 20.5C11.0078 18.8431 12.351 17.5 14.0078 17.5H45.0078C46.6647 17.5 48.0078 18.8431 48.0078 20.5V20.5C48.0078 22.1569 46.6647 23.5 45.0078 23.5H14.0078C12.351 23.5 11.0078 22.1569 11.0078 20.5V20.5Z"
                          fill="white"
                        />
                        <path
                          d="M11.0078 32.5C11.0078 30.8431 12.351 29.5 14.0078 29.5H45.0078C46.6647 29.5 48.0078 30.8431 48.0078 32.5V32.5C48.0078 34.1569 46.6647 35.5 45.0078 35.5H14.0078C12.351 35.5 11.0078 34.1569 11.0078 32.5V32.5Z"
                          fill="white"
                        />
                        <path
                          d="M11.0078 44.5C11.0078 42.8431 12.351 41.5 14.0078 41.5H30.0078C31.6647 41.5 33.0078 42.8431 33.0078 44.5V44.5C33.0078 46.1569 31.6647 47.5 30.0078 47.5H14.0078C12.351 47.5 11.0078 46.1569 11.0078 44.5V44.5Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <p class="selectMessage">No document found.</p>
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </mat-tab>
  </mat-tab-group>
</div> -->
