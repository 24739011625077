<mat-sidenav-container *ngIf="!isInaccessible" class="sidenav-container">
  <!-- <mat-sidenav #drawer [class]="(isTablet$ | async) ? 'sidenav-tablet' : 'sidenav'"
    fixedInViewport="(isHandset$ | async) === false" [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="(isHandset$ | async) === false"> -->
    <mat-sidenav #drawer  [class]="(breakPointService.isTablet$ | async) ? 'sidenav-tablet' : 'sidenav'" [ngClass]="{'side-nav-mobile' : (breakPointService.isHandset$ | async)}" fixedInViewport="true"
    role="navigation" mode="side" [opened]="(breakPointService.isHandset$ | async) ? sideMenuopen : true" [disableClose]="true">

    <ng-container  *ngIf="(breakPointService.isHandset$ | async) === false">
      <mat-toolbar class="gp-logo" *ngIf="currentGPSLogo.bigUrl && currentGPSLogo.smallUrl; else logoLoader">
        <img *ngIf="breakPointService.isTablet$ | async; else bigUrl" src="{{ currentGPSLogo.smallUrl }}" />
        <ng-template #bigUrl>
          <img src="{{ currentGPSLogo.bigUrl }}" />
        </ng-template>
      </mat-toolbar>
    </ng-container>

    <!-- <div *ngIf="isHandset$ | async" class="handsetDropDown">
      <mat-form-field appearance="fill">
        <mat-select
          [(value)]="selectedInvestor"
          (selectionChange)="onLPSChange($event.value)"
        >
          <mat-option
            *ngFor="let investor of investors"
            [value]="investor"
            (click)="drawer.close()"
          >
            {{ investor.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div> -->

    <div *ngIf="breakPointService.isHandset$ | async" class="handsetDropDown">
      <mat-form-field appearance="fill" *ngIf="investors.length > 1 ; else singleInvestorMobile"
        [matTooltip]="selectedInvestor.name" matTooltipPosition="below"
        [matTooltipDisabled]="selectedInvestor.name.length<10">
        <img src="../../assets/images/lp_icon.svg" matPrefix />
        <mat-select [(value)]="selectedInvestor" (selectionChange)="onLPSChange($event.value)" #mobileInvestorSelect>
          <mat-option *ngFor="let investor of investors" [value]="investor" (click)="drawer.close()">
            {{ investor.name }}
          </mat-option>
        </mat-select>
        <img [ngClass]="{ 'open': mobileInvestorSelect.panelOpen}" class="arrow-down"
          src="../../assets/images/arrow-down.svg" matSuffix />
      </mat-form-field>
      <ng-template #singleInvestorMobile>
        <div class="single-investor-mobile" [matTooltip]="selectedInvestor?.name" matTooltipPosition="below"
          [matTooltipDisabled]="selectedInvestor?.name?.length<14">
          <img src="../../assets/images/lp_icon.svg" alt="gp-icon">
          <div>{{selectedInvestor.name}}</div>
        </div>
      </ng-template>
    </div>

    <!-- <mat-nav-list class="navList" *ngIf="isHandset$ | async">
      <ng-container *ngIf="!!privileges.length; else menuItemLoader">
        <div *ngFor="let item of privileges" mat-list-item [routerLink]="
            '/lp/' +
            selectedInvestor.id +
            '/vehicle/' +
            selectedFund.id +
            item.path
          " (click)="navigateToComponent(item)" routerLinkActive="active-link" (click)="onSelectedMenu(item)"
          (click)="drawer.close()">
          <img class="icon" [src]="item.activeimgURL" [ngClass]="
              selectedMenu.value === item.value
                ? 'showActiveImg'
                : 'hideActiveImg'
            " />
          <img class="icon" [src]="item.imgURL" [ngClass]="
              selectedMenu.value !== item.value
                ? 'showInactiveImg'
                : 'hideInactiveImg'
            " />
          <span class="icon-title" *ngIf="(isTablet$ | async) === false"
            [ngClass]="item.value === 'dashboard' ? 'dashboard-icon' : ''">{{ 'translations.' + item.value + '.title' |
            translate }}</span>
        </div>
      </ng-container>
    </mat-nav-list>

    <mat-nav-list class="navList" *ngIf="(isHandset$ | async) === false">
      <ng-container *ngIf="!!privileges.length; else menuItemLoader">
        <div *ngFor="let item of privileges" class="menuItem" mat-list-item [routerLink]="
            '/lp/' +
            selectedInvestor.id +
            '/vehicle/' +
            selectedFund.id +
            item.path
          " (click)="navigateToComponent(item)" routerLinkActive="active-link" (click)="onSelectedMenu(item)">
          <img class="icon" [src]="item.activeimgURL" [ngClass]="
              selectedMenu.value === item.value
                ? 'showActiveImg'
                : 'hideActiveImg'
            " />
          <img class="icon" [src]="item.imgURL" [ngClass]="
              selectedMenu.value !== item.value
                ? 'showInactiveImg'
                : 'hideInactiveImg'
            " />
          <span class="icon-title" *ngIf="(isTablet$ | async) === false"
            [ngClass]="item.value === 'dashboard' ? 'dashboard-icon' : ''">{{ 'translations.' + item.value + '.title' |
            translate }}</span>
        </div>
      </ng-container>
    </mat-nav-list>

    <mat-nav-list class="navList2">
      <div *ngIf="sessionUser.confirmedTermsOfUse" (click)="openTermsOfUse()" class="menuItem">
        <img src="../../../../assets/terms-of-use.png" />

        <span class="icon-title" *ngIf="(isTablet$ | async) === false">
          Terms of use
        </span>
      </div>
      <div (click)="openDialog(false, '')" class="menuItem">
        <img src="../../../../assets/logout.png" />
        <span class="icon-title" *ngIf="(isTablet$ | async) === false">{{
          'translations.logOut' | translate
          }}</span>
      </div>
    </mat-nav-list> -->

    <ng-container *ngIf="!!privileges.length; else menuItemLoader">
      <!-- mobile screen menu item -->
      <mat-nav-list class="navList" *ngIf="breakPointService.isHandset$ | async">
        <ng-container *ngFor="let item of privileges">
          <mat-divider *ngIf="item.value == 'Settings'"></mat-divider>
          <div mat-list-item class="menuItem" [routerLink]="!item.subMenus ? ['/lp/' + selectedInvestor.id + '/vehicle/' + selectedFund.id + item.path] : null"
            routerLinkActive="active-link"
            (keyup.enter)="!item.subMenus && navigateToComponent(item)" 
            (click)="!item.subMenus && navigateToComponent(item)"        
            (click)="!item.subMenus && onSelectedMenu(item)"         
            (click)="item.isToggled = !item.isToggled"
            (click)="!item.subMenus && drawer.close()"         
            tabindex="0"
            >
            <!-- <img class="icon" [src]="item.activeimgURL" [ngClass]="
                selectedMenu.value === item.value
                  ? 'showActiveImg'
                  : 'hideActiveImg'
              " /> -->
            <span class="icon" [ngClass]="selectedMenu.value === item.value ? 'showActiveImg' : 'hideActiveImg'">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <ng-container *ngFor="let svgPath of item.svg">
                  <path [attr.d]="svgPath.d" [attr.stroke]="svgPath.stroke ? svgPath.stroke : null"
                    [attr.stroke-width]="svgPath.strokeWidth ? svgPath.strokeWidth : null"
                    [attr.stroke-linecap]="svgPath.strokeLinecap ? svgPath.strokeLinecap : null"
                    [attr.stroke-linejoin]="svgPath.strokeLinejoin ? svgPath.strokeLinejoin : null" />
                </ng-container>
              </svg>
            </span>
            <img class="icon" [src]="item.imgURL" [ngClass]="
                selectedMenu.value !== item.value
                  ? 'showInactiveImg'
                  : 'hideInactiveImg'
              " />
            <span class="icon-title" *ngIf="(breakPointService.isTablet$ | async) === false"
              [ngClass]="item.value === 'Dashboard' ? 'dashboard-icon' : ''">
              {{ item.viewValue }}</span>
              <!-- Display expand/collapse arrow if the item has submenus -->
            <div *ngIf="item.subMenus" style="margin-left: auto;">
              <img *ngIf="!item.isToggled" class="arrow-down" src="../../assets/images/arrow-down.svg" alt="Expand" />
              <img *ngIf="item.isToggled" class="arrow-down open" src="../../assets/images/arrow-down.svg"  alt="Collapse" />
            </div>
          </div>
          <div *ngIf="item.subMenus && item.isToggled && ((breakPointService.isHandset$ | async))">
            <ng-container *ngFor="let subItem of item.subMenus">
              <span mat-list-item class="menuItem subMenuItem" *ngIf="!shouldHideSubmenu(subItem)" [routerLink]="
                '/lp/' +
                selectedInvestor.id +
                '/vehicle/' +
                selectedFund.id + '/' + item.path + '/' +
                subItem.path" routerLinkActive="active-link"
                (keyup.enter)="navigateToSubmenu(item.path + '/' + subItem.path)"
                (click)="navigateToSubmenu(item.path +'/'+ subItem.path)" (click)="onSelectedMenu(item)"
                (click)="drawer.close()">
                <span>
                  {{ subItem.viewValue }}
                </span>
              </span>
            </ng-container>
          </div>
        </ng-container>
      </mat-nav-list>
      <!-- desktop screen menu item -->
      <mat-nav-list class="navList" *ngIf="(!(breakPointService.isHandset$ | async) && !(breakPointService.isTablet$ | async))">
        <ng-container *ngFor="let item of privileges">
          <mat-divider *ngIf="item.value == 'Settings'"></mat-divider>
          <div mat-list-item class="menuItem"
            [routerLink]="!item.subMenus ? ['/lp/' + selectedInvestor.id + '/vehicle/' + selectedFund.id + item.path] : null"
            routerLinkActive="active-link" 
            (keyup.enter)="!item.subMenus && navigateToComponent(item)" 
            (click)="!item.subMenus && navigateToComponent(item)"        
            (click)="!item.subMenus && onSelectedMenu(item)"         
            (click)="item.isToggled = !item.isToggled"           
            tabindex="0">
            <!-- <img class="icon" [src]="item.activeimgURL"
              [ngClass]="selectedMenu.value === item.value ? 'showActiveImg' : 'hideActiveImg'" /> -->
            <span class="icon" [ngClass]="selectedMenu.value === item.value && !item.subMenus ? 'showActiveImg' : 'hideActiveImg'">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <ng-container *ngFor="let svgPath of item.svg">
                  <path [attr.d]="svgPath.d" [attr.stroke]="svgPath.stroke ? svgPath.stroke : null"
                    [attr.stroke-width]="svgPath.strokeWidth ? svgPath.strokeWidth : null"
                    [attr.stroke-linecap]="svgPath.strokeLinecap ? svgPath.strokeLinecap : null"
                    [attr.stroke-linejoin]="svgPath.strokeLinejoin ? svgPath.strokeLinejoin : null" />
                </ng-container>
              </svg>
            </span>

            <img class="icon" [src]="item.imgURL"
              [ngClass]="selectedMenu.value !== item.value || item.subMenus ? 'showInactiveImg' : 'hideInactiveImg'" />
            <span class="icon-title" [ngClass]="item.value === 'Dashboard' ? 'dashboard-icon' : ''">
              {{ item.viewValue }}
            </span>

            <!-- Display expand/collapse arrow if the item has submenus -->
            <div *ngIf="item.subMenus" style="margin-left: auto;">
              <img *ngIf="!item.isToggled" class="arrow-down" src="../../assets/images/arrow-down.svg" alt="Expand" />
              <img *ngIf="item.isToggled" class="arrow-down open" src="../../assets/images/arrow-down.svg"  alt="Collapse" />
            </div>

          </div>
          <div *ngIf="item.subMenus && item.isToggled && (!(breakPointService.isHandset$ | async) && !(breakPointService.isTablet$ | async))">
            <ng-container *ngFor="let subItem of item.subMenus">
               
              <span mat-list-item class="menuItem subMenuItem" *ngIf="!shouldHideSubmenu(subItem)"[routerLink]="
                '/lp/' +
                selectedInvestor.id +
                '/vehicle/' +
                selectedFund.id + '/' + item.path + '/' +
                subItem.path" routerLinkActive="active-link"
                (keyup.enter)="navigateToSubmenu(item.path + '/' + subItem.path)"
                (click)="navigateToSubmenu(item.path +'/'+ subItem.path)" (click)="onSelectedMenu(item)">
                <span>
                  {{ subItem.viewValue }}
                </span>
              </span>
            </ng-container>
          </div>
        </ng-container>
      </mat-nav-list>

      <!-- tablet screen menu item -->
      <mat-nav-list class="navList" *ngIf="(breakPointService.isTablet$ | async)">
        <ng-container *ngFor="let item of privileges">
          <mat-divider *ngIf="item.value == 'Settings'"></mat-divider>
          <div mat-list-item class="menuItem"
            *ngIf="!item.subMenus"
            [routerLink]="!item.subMenus ? ['/lp/' + selectedInvestor.id + '/vehicle/' + selectedFund.id + '/' + item.path ] : null"
            routerLinkActive="active-link"
            (keyup.enter)="navigateToComponent(item)"
            (click)="!item.subMenus && navigateToComponent(item)"
            (click)="onSelectedMenu(item)"
            tabindex="0">
            <!-- <img class="icon" [src]="item.activeimgURL" alt="icon"
              [ngClass]="selectedMenu.value === item.value ? 'showActiveImg' : 'hideActiveImg'" /> -->
            <span class="icon" [ngClass]="selectedMenu.value === item.value ? 'showActiveImg' : 'hideActiveImg'">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <ng-container *ngFor="let svgPath of item.svg">
                  <path [attr.d]="svgPath.d" [attr.stroke]="svgPath.stroke ? svgPath.stroke : null"
                    [attr.stroke-width]="svgPath.strokeWidth ? svgPath.strokeWidth : null"
                    [attr.stroke-linecap]="svgPath.strokeLinecap ? svgPath.strokeLinecap : null"
                    [attr.stroke-linejoin]="svgPath.strokeLinejoin ? svgPath.strokeLinejoin : null" />
                </ng-container>
              </svg>
            </span>
            <img class="icon" [src]="item.imgURL"
              [ngClass]="selectedMenu.value !== item.value ? 'showActiveImg' : 'hideActiveImg'" alt="Menu Icon" />
          </div>
          <div *ngIf="item.subMenus" mat-list-item [ngClass]="isSubMenuActive(item)" tabindex="0" [matMenuTriggerFor]="menu">
            <span class="icon" [ngClass]="selectedMenu.value === item.value ? 'showActiveImg' : 'hideActiveImg'">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <ng-container *ngFor="let svgPath of item.svg">
                  <path [attr.d]="svgPath.d" [attr.stroke]="svgPath.stroke ? svgPath.stroke : null"
                    [attr.stroke-width]="svgPath.strokeWidth ? svgPath.strokeWidth : null"
                    [attr.stroke-linecap]="svgPath.strokeLinecap ? svgPath.strokeLinecap : null"
                    [attr.stroke-linejoin]="svgPath.strokeLinejoin ? svgPath.strokeLinejoin : null" />
                </ng-container>
              </svg>
            </span>
            <img class="icon" [src]="item.imgURL"
            [ngClass]="selectedMenu.value !== item.value ? 'showActiveImg' : 'hideActiveImg'" alt="Menu Icon" />          </div>
          <mat-menu #menu="matMenu" class="custom-menu custom-menu-tab" yPosition="below" xPosition="after">
            <div class="custom-menu-header">
              {{ item.viewValue }}
            </div>
            <ng-container *ngFor="let subItem of item.subMenus" class="custom-mat-container">
              <div *ngIf="!shouldHideSubmenu(subItem)" mat-list-item class="custom-menu-item custom-item-tab" [routerLink]="
                  '/lp/' +
                  selectedInvestor.id +
                  '/vehicle/' +
                  selectedFund.id  + item.path + '/' +
                  subItem.path" routerLinkActive="active-link"
                (keyup.enter)="navigateToSubmenu(item.path + '/' + subItem.path)"
                (click)="navigateToSubmenu(item.path + '/' + subItem.path)" (click)="onSelectedMenu(item)"
                >
                {{ subItem.viewValue }}
              </div>
            </ng-container>
          </mat-menu>
        </ng-container>
      </mat-nav-list>

      <div class="sidenav-footer">
        <mat-nav-list class="navList">
          <div  mat-list-item class=" nav-item-base" (click)="openGpPortal()" *ngIf="gpPortalLoginEnabled" >
            <div class=" nav-content">

              <img src="../../../../assets/images/gp_icon.svg" alt="icon" />
              <span class="icon-title" *ngIf="(breakPointService.isTablet$ | async) === false">
                Go to GP Portal
              </span>
            </div>

            <img src="../../../../assets/images/external_link_icon.svg" alt="icon" *ngIf="(breakPointService.isTablet$ | async) === false" />
          </div>
          <div mat-list-item (click)="openTermsOfUse()" class="menuItem">
            <img src="../../../../assets/images/terms_icon.svg" alt="icon" />

            <span class="icon-title" *ngIf="(breakPointService.isTablet$ | async) === false">
              Terms of use
            </span>
          </div>
          <div mat-list-item (click)="openDialog(false, '')" class="menuItem">
            <img src="../../../../assets/images/signout_icon.svg" alt="icon" />
            <span class="icon-title" *ngIf="(breakPointService.isTablet$ | async) === false">{{
              'translations.logOut' | translate
              }}</span>
          </div>
        </mat-nav-list>
        <div class="copy-rites">
          {{(breakPointService.isTablet$ | async)? "" : "© 2024 ACE Alternatives."}}
        </div>
      </div>
    </ng-container>



  </mat-sidenav>

  <mat-sidenav-content class="{{ activeClassName }} componentClass" (click)="closeSidebarIfOpen($event)"
    [ngClass]="(breakPointService.isTablet$ | async) ? 'tablet' : (breakPointService.isHandset$| async) ? 'mobile' : 'desktop'">
    <div class="header" [ngClass]="{'header-height' : breakPointService.isHandset$ | async}">
      <!-- [ngStyle]="{'--mobile-header-height': funds.length === 1 ? '118px' : '178px'}" -->
      <ng-container  *ngIf="(breakPointService.isHandset$ | async) === false">
      <!-- <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()"
        *ngIf="isHandset$ | async" style="z-index: 1">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button> -->
      <div class="dashBoardTitle">
        <!-- <mat-form-field appearance="fill" class="fundDropdown">
              <mat-select
                [(value)]="selectedFund"
                (selectionChange)="onVehicleChange($event.value)"
              >
                <mat-option *ngFor="let fund of funds" [value]="fund">
                  {{ getVehicleName(fund) }}
                </mat-option>
              </mat-select>
            </mat-form-field> -->
        <div class="fund-dropdown">
        <mat-form-field appearance="fill" class="fundDropdown" *ngIf="funds.length > 1"
          [matTooltip]="selectedFund?.name" matTooltipPosition="below"
          [matTooltipDisabled]="selectedFund?.name?.length<10">
          <img src="../../assets/images/fund_icon.svg" matPrefix />
          <mat-select [(value)]="selectedFund" (selectionChange)="onVehicleChange($event.value)" #fundSelect>
            <ng-container *ngFor="let fund of funds">
              <mat-option class="fund-item" *ngIf="getVehicleName(fund).active" [value]="fund">
                {{ getVehicleName(fund).name }}
              </mat-option>
            </ng-container>
          </mat-select>
          <img class="arrow-down" [ngClass]="{ 'open': fundSelect.panelOpen}" src="../../assets/images/arrow-down.svg"
            matSuffix />
        </mat-form-field>
      </div>
        <div class="bread-crumb">
          <ng-container *ngIf="funds.length == 1">
            <div class="single-fund">
              <img src="../../assets/images/fund_icon.svg">
              <div>{{getVehicleName(selectedFund).name}}</div>
            </div>
            <img src="../../../../assets/images/arrow_right_icon.svg" height="16px" width="16px" alt="">
          </ng-container>
            <span class="primary-path"
            (click)="breadCrumbNavigate(suffixMessage, newMenuItem, 'primary')"
            [ngClass]="{'hover': ((suffixMessage || selectedChildItem) && newMenuItem != 'insights')}"
            [ngStyle]="{'pointer-events': !(suffixMessage || selectedChildItem) ? 'none' : 'auto'}">
            {{ 'translations.' + newMenuItem + '.title' | translate }}</span>
          <ng-container *ngIf="!(breakPointService.isHandset$ | async)">
            <ng-container *ngIf="suffixMessage">
              <ng-container *ngFor="let path of getSecondaryPath(suffixMessage, 'suffix') index as i">
                <img src="../../../../assets/images/arrow_right_icon.svg" alt="">
                <span class="path-name"
                (click)="breadCrumbNavigate(suffixMessage, newMenuItem, 'suffix', i)"
                [ngClass]="{'hover': (i+1) !== getSecondaryPath(suffixMessage, 'suffix').length}"
                [ngStyle]="{'pointer-events': ((i+1) !== getSecondaryPath(suffixMessage, 'suffix').length && newMenuItem !== 'reports') ? 'auto' : 'none'}">
                {{path}}</span>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="selectedChildItem">
              <ng-container *ngFor="let path of getSecondaryPath('translations.' + selectedChildItem, 'prefix')">
                <img src="../../../../assets/images/arrow_right_icon.svg" alt="">
                <span class="path-name">{{path}}</span>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>

        <!-- <span class="menuHeading">
          {{ 'translations.' + newMenuItem + '.title' | translate }}
          <ng-container *ngIf="selectedChildItem">
            <span class="childMenuItem"><ng-container class="childMenuItem">
                {{ pathSeparator }}
              </ng-container>
              {{ 'translations.' + selectedChildItem | translate }}
            </span></ng-container>

          <ng-container *ngIf="suffixMessage">
            <span class="childMenuItem"><ng-container class="childMenuItem"> </ng-container>
              {{ suffixMessage }}
            </span></ng-container>
        </span> -->
      </div>
    </ng-container>
    <!-- mobile view -->
     <ng-container *ngIf="(breakPointService.isHandset$ | async)">
      <div class="mobile-header-container" [ngClass]="{'no-funds': funds.length == 1}">
        <div class="top-menu-container">
            <div (click)="drawer.toggle();sideMenuopen = !sideMenuopen;$event.stopPropagation()" class="mobile-menu-icon">
              <img src="assets/images/menu_icon.svg" alt="menu_icon" height="24px" width="24px">
            </div>
            <div *ngIf="currentGPSLogo.bigUrl && currentGPSLogo.smallUrl; else logoLoader" class="app-logo">
              <img *ngIf="breakPointService.isTablet$ | async; else bigUrl" src="{{ currentGPSLogo.smallUrl }}"  height="38px"/>
              <ng-template #bigUrl>
                <img src="{{ currentGPSLogo.bigUrl }}" height="38px"/>
              </ng-template>
            </div>
            <img src="assets/images/user_logo_icon.svg" height="20px" width="20px" alt="help-desk" class="icon-logo"/>
        </div>
        <div class="below-menu-container">
          <div class="lp-dropdown" *ngIf="funds.length > 1">
            <mat-form-field appearance="fill" class="fundDropdown"
              [matTooltip]="selectedFund?.name" matTooltipPosition="below"
              [matTooltipDisabled]="selectedFund?.name?.length<10">
              <img src="../../assets/images/fund_icon.svg" matPrefix />
              <mat-select [(value)]="selectedFund" (selectionChange)="onVehicleChange($event.value)" #fundSelect [disabled]="sideMenuopen">
                <ng-container *ngFor="let fund of funds">
                  <mat-option class="fund-item" *ngIf="getVehicleName(fund).active" [value]="fund">
                    {{ getVehicleName(fund).name }}
                  </mat-option>
                </ng-container>
              </mat-select>
              <img class="arrow-down" [ngClass]="{ 'open': fundSelect.panelOpen}" src="../../assets/images/arrow-down.svg"
                matSuffix />
            </mat-form-field>
          </div>
          <div class="bread-crumb-mob" [ngClass]="{'fund-breadcrumb' : funds.length > 1 }">
            <ng-container *ngIf="funds.length == 1">
              <div class="single-fund">
                <img src="../../assets/images/fund_icon.svg">
                <div>{{getVehicleName(selectedFund)}}.name</div>
              </div>
              <img src="../../../../assets/images/arrow_right_icon.svg" height="16px" width="16px" alt="">
            </ng-container>
              <span class="primary-path"
              (click)="breadCrumbNavigate(suffixMessage, newMenuItem, 'primary')"
              [ngClass]="{'hover': (suffixMessage || selectedChildItem)}"
              [ngStyle]="{'pointer-events': !(suffixMessage || selectedChildItem) ? 'none' : 'auto'}">
              {{ 'translations.' + newMenuItem + '.title' | translate }}</span>
              <ng-container *ngIf="suffixMessage">
                <ng-container *ngFor="let path of getSecondaryPath(suffixMessage, 'suffix') index as i">
                  <img src="../../../../assets/images/arrow_right_icon.svg" alt="">
                  <span class="path-name"
                  (click)="breadCrumbNavigate(suffixMessage, newMenuItem, 'suffix', i)"
                  [ngClass]="{'hover': (i+1) !== getSecondaryPath(suffixMessage, 'suffix').length}"
                  [ngStyle]="{'pointer-events': ((i+1) !== getSecondaryPath(suffixMessage, 'suffix').length && newMenuItem !== 'reports') ? 'auto' : 'none'}">
                  {{path}}</span>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="selectedChildItem">
                <ng-container *ngFor="let path of getSecondaryPath('translations.' + selectedChildItem, 'prefix')">
                  <img src="../../../../assets/images/arrow_right_icon.svg" alt="">
                  <span class="path-name">{{path}}</span>
                </ng-container>
              </ng-container>
          </div>
        </div>
      </div>
     </ng-container>
      <div *ngIf="(breakPointService.isHandset$ | async) === false" class="investorDropdown">
        <!-- <mat-form-field appearance="fill">
            <mat-select [(value)]="selectedInvestor" (selectionChange)="onLPSChange($event.value)">
              <mat-option *ngFor="let investor of investors" [value]="investor">
                {{ investor.name }}
              </mat-option>
            </mat-select>
          </mat-form-field> -->

        <mat-form-field appearance="fill" *ngIf="investors.length > 1 ; else singleInvestor"
          [matTooltip]="selectedInvestor?.name" matTooltipPosition="below"
          [matTooltipDisabled]="selectedInvestor?.name?.length<10">
          <img src="../../assets/images/lp_icon.svg" matPrefix />
          <mat-select [(value)]="selectedInvestor" (selectionChange)="onLPSChange($event.value)" #investorSelect>
            <mat-option class="investor-item" *ngFor="let investor of investors" [value]="investor"
              [matTooltip]="investor?.name" matTooltipPosition="below" [matTooltipDisabled]="investor?.name?.length<25">
              {{ investor.name }}
            </mat-option>
          </mat-select>
          <img class="arrow-down" [ngClass]="{ 'open': investorSelect.panelOpen}"
            src="../../assets/images/arrow-down.svg" matSuffix />
        </mat-form-field>
        <ng-template #singleInvestor>
          <div class="single-investor" [matTooltip]="selectedInvestor?.name" matTooltipPosition="below"
            [matTooltipDisabled]="selectedInvestor?.name?.length<14">
            <img src="../../assets/images/lp_icon.svg" alt="gp-icon">
            <div>{{selectedInvestor.name}}</div>
          </div>
        </ng-template>
      </div>
      <!-- <div
          [routerLink]="'/account'"
          (click)="onSelectedMenu('profile')"
          routerLinkActive="profile-active-link"
          *ngIf="(isHandset$ | async) === false"
          class="profile"
        >
          <div>
            <img
              *ngIf="
                activeClassName === 'dashboard-component' ||
                  activeClassName === 'default-component';
                else darkProfile
              "
              src="../../assets/account_circle.svg"
            />
            <ng-template #darkProfile>
              <img src="../../assets/account_circle_black.svg" />
            </ng-template>
          </div>
        </div> -->

    </div>

    <div style="padding: 0 24px;">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>

</mat-sidenav-container>

<ng-template #menuItemLoader>
  <div *ngFor="let number of [0, 1, 2, 3, 4]">
    <div class="menuItemLoader">
      <ngx-skeleton-loader count="1" appearance="line" [theme]="{
          width: '24px',
          height: '24px',
          margin: '0px'
        }"></ngx-skeleton-loader>
      <ngx-skeleton-loader *ngIf="(breakPointService.isTablet$ | async) === false" count="1" appearance="line" [theme]="{
          width: '172px',
          height: '24px',
          margin: '0px'
        }"></ngx-skeleton-loader>
    </div>
  </div>
</ng-template>

<ng-template #logoLoader>
  <div class="logoLoader">
    <ngx-skeleton-loader *ngIf="!(breakPointService.isTablet$ | async)" count="1" appearance="line" [theme]="{
        width: '75px',
        margin: '0px',
        height: '38px'
      }"></ngx-skeleton-loader>
    <ngx-skeleton-loader *ngIf="breakPointService.isTablet$ | async" count="1" appearance="line" [theme]="{
        width: '60px',
        margin: '0px',
        height: '38px'
      }"></ngx-skeleton-loader>
  </div>
</ng-template>