import { Component, Input } from '@angular/core';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { convert } from 'src/app/utils/numberConvertion.util';

@Component({
  selector: 'app-fund-metrics',
  templateUrl: './fund-metrics.component.html',
  styleUrls: ['./fund-metrics.component.scss', '../insights.component.scss']
})
export class FundMetricsComponent {
  @Input() fundMetrics: any[] = [];
  @Input() fundMetricsSegments: { [key: string]: string } = {};
  @Input() legendColors: string[] = [];
  @Input() selectedVehicle: any;

  isOneColumnView$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.Handset, Breakpoints.TabletPortrait])
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Handset])
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  isTablet$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.Medium])
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  constructor(private breakpointObserver: BreakpointObserver) {}

  convertCustomValue(kpi: any) {
    let format = 'en-US';
    if (this.selectedVehicle.separators.decimal === ',') {
      format = 'de-DE';
    }
    const numFor = new Intl.NumberFormat(format, {
      minimumFractionDigits: kpi.decimalPlaces,
      maximumFractionDigits: kpi.decimalPlaces
    });
    return numFor.format(kpi.value.toFixed(kpi.decimalPlaces));
  }

  convertCurrency(value: any) {
    return convert(value.value, value.type, this.selectedVehicle);
  }

  scrollToSection(sectionId: string) {
    const element = document.getElementById(sectionId);
    if (element) {
      // First, scroll the element into view smoothly
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }
  
}
