import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';
import { InsightsService } from 'src/app/services/insights.service';
import { PortfolioCompany } from './types';
import { MatTableDataSource } from '@angular/material/table';
import { ApiHttpService } from '../../services/api-http.service';
import { convert } from 'src/app/utils/numberConvertion.util';
import { BehaviorSubject } from 'rxjs';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { ActivatedRoute, Router } from '@angular/router';
import { ConfigService } from 'src/app/initializer/config.service';
import { Location } from '@angular/common';
import { PortfolioCompaniesComponent } from './portfolio-companies/portfolio-companies.component';
import { environment } from 'src/environments/environment';
import { FundMetricsComponent } from './fund-metrics/fund-metrics.component';
import { PortfolioInvestmentComponent } from './portfolio-investment/portfolio-investment.component';
import { FundInvestmentsComponent } from './fund-investments/fund-investments.component';

export interface TransactionElements {
  reportTitle: string;
  type: string;
  sendDate: string;
  dueDate: string;
  amount: string;
  status: string;
}

@Component({
  selector: 'app-insights',
  templateUrl: './insights.component.html',
  styleUrls: ['./insights.component.scss']
})
export class InsightsComponent implements OnInit {
  @ViewChild(PortfolioCompaniesComponent, { static: true })
  portfolioCompaniesComponent!: PortfolioCompaniesComponent;

  /** Based on the screen size, switch from standard to one column per row */
  portfolioCompanies: PortfolioCompany[] = [];
  isLoading: boolean = false;
  public displayedColumns = [
    'companyLogoPath',
    'initialInvestment',
    'latestInvestment',
    'status',
    'latestValuation',
    'currentYearRevenue',
    'companyValue'
  ];

  isInitialVsFollowOnUpdated$ = new BehaviorSubject(true);
  isInitialPacingUpdated$ = new BehaviorSubject(true);
  isFollowOnPacingUpdated$ = new BehaviorSubject(true);
  isAverageHoldingPeriodUpdated$ = new BehaviorSubject(true);
  public dataSource = new MatTableDataSource<PortfolioCompany>();
  fundMetrics: any = [];
  portfolioInvestments: any = [];
  filteredFundMetrics: any = [];
  filteredPortfolioInvestments: any = [];
  fundInvestments: any = [];
  selectedLP: any;
  selectedVehicle: any;
  legendColors: any = [];
  fundMetricsSegments: { [key: string]: string } = {
    netPerformance: 'Net Performance',
    grossPerformance: 'Gross Performance',
    lpDetails: 'LP Details',
    gpDetails: 'GP Details',
    fundFinancialStatementandOperatingExpenses:
      'Fund Financial Statement and Operating Expenses'
  };

  tabs: any = ['fund-metrics', 'portfolio-investments', 'portfolio-companies','fund-investments'];
  tabTitles: any = [
    'insights.funMetrics',
    'insights.portfolioInvestments',
    'insights.portfolioCompanies',
    'insights.fundInvestments'
  ];
  insightsTab: any = 0;
  portFolioInvestmentSegments: { [key: string]: string } = {
    capitalDeployment: 'Capital Deployment',
    exits: 'Exits'
  };
  lpId: any;
  vehicleId: any;
  initialAndFollowOnInvestments: any = {};
  initialInvestmentPacing: any = {};
  followOnInvestmentPacing: any = {};
  averageHoldingPeriod: any = {};
  companyId: any;

  selectedInitialAndFollowOnInvestments: any = {};
  selectedInitialInvestmentPacing: any = {};
  selectedFollowOnInvestmentPacing: any = {};
  selectedAverageHoldingPeriod: any = {};

  portfolioCompanyList: any;
  disableScrollListener: boolean = false;
  isOneColumnView$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.Handset, Breakpoints.TabletPortrait])
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.XSmall, Breakpoints.Small , Breakpoints.Handset])
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  isTablet$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.Medium])
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  hideInsights: boolean = true;
  activeTab: string = ''; // Add this line
  @ViewChild(FundMetricsComponent) FundMetricsComponent!: FundMetricsComponent;
  @ViewChild(PortfolioInvestmentComponent)
  PortfolioInvestmentComponent!: PortfolioInvestmentComponent;
  @ViewChild(FundInvestmentsComponent) FundInvestmentsComponent!: FundInvestmentsComponent;
  fundOfFunds: boolean =true

  constructor(
    private breakpointObserver: BreakpointObserver,
    private notificationService: NotificationService,
    private apiHttpService: ApiHttpService,
    private insightsService: InsightsService,
    private config: ConfigService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _location: Location
  ) {}

  onScroll(metricsData: any) {
    if(this.disableScrollListener) {
      return;
    }
    let currentSection = this.activeTab;
    metricsData.forEach((segment: any) => {
    const sectionElement = document.getElementById(segment.labelKey);
      if (sectionElement) {
        const rect = sectionElement.getBoundingClientRect();
        // down scroll
        if (rect.top < window.innerHeight * 0.6 && rect.bottom > window.innerHeight * 0.6) {
          currentSection = segment.labelKey;
        }
        // top scroll
         if (rect.top < window.innerHeight * 0.6 && rect.bottom > 0) {
          currentSection = segment.labelKey;
        }
      }
    });
    if (currentSection) {
      this.activeTab = currentSection;
    }
  }

  public tabChanged(tabChangeEvent: any): void {
    const messages = [
      'insights.funMetrics',
      'insights.portfolioInvestments',
      'insights.portfolioCompanies',
      'insights.fundInvestments'
    ];
    const currentTab = this.tabs[tabChangeEvent.index] || 'fund-metrics';
    this.router.navigate([`lp/${this.lpId}/vehicle/${this.vehicleId}/insights/${currentTab}`],{ skipLocationChange: true });
    // this._location.replaceState(
    //   `lp/${this.lpId}/vehicle/${this.vehicleId}/insights/${currentTab}`
    // );
    this.notificationService.sendNotification({
      prefix: messages[tabChangeEvent.index],
      suffix: ''
    });

    // if(tabChangeEvent.index == 2) {
    //     this.portfolioCompaniesComponent.ngOnInit();
    // }
  }
  chartData: any;

  scrollToSection(sectionId: string) {
    this.disableScrollListener = true;
    // Call the child's method to scroll to the section
    if (this.FundMetricsComponent && this.insightsTab === 'fund-metrics') {
      this.FundMetricsComponent.scrollToSection(sectionId);
      this.activeTab = sectionId;
    }
    if (this.PortfolioInvestmentComponent && this.insightsTab === 'portfolio-investments') {
      this.PortfolioInvestmentComponent.scrollToSection(sectionId);
      this.activeTab = sectionId;
    }
    setTimeout(() => {
      this.disableScrollListener = false;
    }, 1000);
  }

  getFundMetrics(lpsId: any, vehicleId: any) {
    this.isLoading = true;
    this.apiHttpService
      .get(this.insightsService.getFundMetrics(lpsId, vehicleId))
      .subscribe((response) => {
        this.isLoading = false;
        let parsedResponse = JSON.parse(JSON.stringify(response));
        this.fundMetrics = parsedResponse.data;
      });
  }

  getFundInvestments(lpsId: any, vehicleId: any) {
    this.apiHttpService
      .get(this.insightsService.getFundInvestments(lpsId, vehicleId))
      .subscribe((response) => {
        let parsedResponse = JSON.parse(JSON.stringify(response));
        this.fundInvestments = parsedResponse.data;
        this.filteredPortfolioInvestments = parsedResponse.data;

        this.initialAndFollowOnInvestments = this.findNestedObj(
          this.fundInvestments,
          'labelKey',
          'Initial investments vs. follow-on investments'
        );
        this.selectedInitialAndFollowOnInvestments =
          this.initialAndFollowOnInvestments?.views[0];
        this.initialInvestmentPacing = this.findNestedObj(
          this.fundInvestments,
          'labelKey',
          'Inital investment pacing (No. of deals)'
        );
        this.selectedInitialInvestmentPacing =
          this.initialInvestmentPacing?.views[0];
        this.followOnInvestmentPacing = this.findNestedObj(
          this.fundInvestments,
          'labelKey',
          'Follow-on investment pacing (No. of deals)'
        );
        this.selectedFollowOnInvestmentPacing =
          this.followOnInvestmentPacing?.views[0];
        this.averageHoldingPeriod = this.findNestedObj(
          this.fundInvestments,
          'labelKey',
          'Average holding period per month'
        );
        this.selectedAverageHoldingPeriod = this.averageHoldingPeriod?.views[0];
      });
  }
  getPortfolioInvestments(lpsId: any, vehicleId: any) {
    this.apiHttpService
      .get(this.insightsService.getPortfolioInvestments(lpsId, vehicleId))
      .subscribe((response) => {
        let parsedResponse = JSON.parse(JSON.stringify(response));
        this.portfolioInvestments = parsedResponse.data;
        this.filteredPortfolioInvestments = parsedResponse.data;

        this.initialAndFollowOnInvestments = this.findNestedObj(
          this.portfolioInvestments,
          'labelKey',
          'Initial investments vs. follow-on investments'
        );
        this.selectedInitialAndFollowOnInvestments =
          this.initialAndFollowOnInvestments?.views[0];
        this.initialInvestmentPacing = this.findNestedObj(
          this.portfolioInvestments,
          'labelKey',
          'Inital investment pacing (No. of deals)'
        );
        this.selectedInitialInvestmentPacing =
          this.initialInvestmentPacing?.views[0];
        this.followOnInvestmentPacing = this.findNestedObj(
          this.portfolioInvestments,
          'labelKey',
          'Follow-on investment pacing (No. of deals)'
        );
        this.selectedFollowOnInvestmentPacing =
          this.followOnInvestmentPacing?.views[0];
        this.averageHoldingPeriod = this.findNestedObj(
          this.portfolioInvestments,
          'labelKey',
          'Average holding period per month'
        );
        this.selectedAverageHoldingPeriod = this.averageHoldingPeriod?.views[0];
      });
  }

  viewChange(event: any) {
    let data = event?.data;
    let dropdownBox = event?.box;
    switch (dropdownBox.labelKey) {
      case 'Initial investments vs. follow-on investments': {
        this.selectedInitialAndFollowOnInvestments = data;
        this.isInitialVsFollowOnUpdated$.next(false);
        setTimeout(() => {
          this.isInitialVsFollowOnUpdated$.next(true);
        }, 0);
        return;
      }
      case 'Inital investment pacing (No. of deals)': {
        this.selectedInitialInvestmentPacing = data;
        this.isInitialPacingUpdated$.next(false);
        setTimeout(() => {
          this.isInitialPacingUpdated$.next(true);
        }, 0);
        return;
      }
      case 'Follow-on investment pacing (No. of deals)': {
        this.selectedFollowOnInvestmentPacing = data;
        this.isFollowOnPacingUpdated$.next(false);
        setTimeout(() => {
          this.isFollowOnPacingUpdated$.next(true);
        }, 0);
        return;
      }
      case 'Average holding period per month': {
        this.selectedAverageHoldingPeriod = data;
        this.isAverageHoldingPeriodUpdated$.next(false);
        setTimeout(() => {
          this.isAverageHoldingPeriodUpdated$.next(true);
        }, 0);
        return;
      }
    }

    this.filteredPortfolioInvestments = this.portfolioInvestments.map(
      (investment: any) => {
        return {
          ...investment,
          boxes: investment.boxes.map((box: any) => {
            if (box.labelKey === dropdownBox.labelKey) {
              return {
                ...box,
                toView: box.views.filter(
                  (view: any) => view.labelKey === data.labelKey
                )
              };
            }
            return box;
          })
        };
      }
    );
  }

  findNestedObj(entireObj: any, keyToFind: any, valToFind: any) {
    let foundObj;
    JSON.stringify(entireObj, (_, nestedValue) => {
      if (nestedValue && nestedValue[keyToFind] === valToFind) {
        foundObj = nestedValue;
      }
      return nestedValue;
    });
    return foundObj;
  }

  convertCurrency(value: any) {
    return convert(value.value, value.type, this.selectedVehicle);
  }

  convertPortfolioValues(value: any) {
    let format = 'en-US';
    if (this.selectedVehicle.separators.decimal === ',') {
      format = 'de-DE';
    }
    const numFor = new Intl.NumberFormat(format);
    return numFor.format(value.toFixed(2));
  }

  ngOnInit() {
    if (environment.hideInsightsPortfolio === true) {
      this.hideInsights = false;
    }
    this.lpId = this.activatedRoute.snapshot.params['lpId'];
    this.vehicleId = this.activatedRoute.snapshot.params['vehicleId'];
    this.insightsTab = this.activatedRoute.snapshot.params['insights-tab'];
    this.companyId = this.activatedRoute.snapshot.params['companyId'];
    let baseconfigData = this.config.baseData.data;
    this.selectedLP = baseconfigData.lps.find(
      (item: any) => item.id === this.lpId
    );
    this.selectedVehicle = baseconfigData.vehicles.find(
      (item: any) => item.id === this.vehicleId
    );

    let currentGP = baseconfigData.gps.find(
      (gp: any) => gp.id === this.selectedVehicle.gpId
    );
    this.legendColors.push(currentGP.colors.primary);
    this.legendColors.push(currentGP.colors.secondary);
    this.legendColors.push(currentGP.colors.primaryLight);
    this.legendColors.push(currentGP.colors.secondaryLight);

    this.getFundMetrics(this.lpId, this.vehicleId);

if(this.selectedVehicle.type ==='fundOfFunds')
{
  this.fundOfFunds=false;
  this.getFundInvestments(this.lpId, this.vehicleId);
}
else
{
  this.getPortfolioInvestments(this.lpId, this.vehicleId);
}
    

    this.tabChanged({ index: this.tabs.indexOf(this.insightsTab) });

    if (!this.hideInsights && this.insightsTab == 'portfolio-companies') {
      this.insightsTab = 'insights.funMetrics';
      this.tabChanged({ index: this.tabs.indexOf(this.insightsTab) });
    }
    let messageToSend =
      this.tabTitles[this.tabs.indexOf(this.insightsTab)] ||
      'insights.funMetrics';
    this.notificationService.sendNotification({
      prefix: messageToSend,
      suffix: ''
    });
  }
}
