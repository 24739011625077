import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Observable, map, shareReplay } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BreakPointService {
  constructor(private breakpointObserver: BreakpointObserver) { }

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.Handset, Breakpoints.XSmall, Breakpoints.Small])
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  isTablet$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Medium)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  isSmallerHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.XSmall)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );
    isSmallHandset$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.XSmall])
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  isSmallScreen$: Observable<boolean> = this.breakpointObserver
    .observe(['(min-width:678px) and (max-width:959.98px)'])
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  isMediumScreen$: Observable<boolean> = this.breakpointObserver
    .observe(['(min-width:600px) and (max-width:677.98px)'])
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  isLargeScreen$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.Large])
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  isXLargeScreen$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.XLarge])
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  isXXLargeScreen$: Observable<boolean> = this.breakpointObserver
    .observe(['(min-width: 2200px)'])
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );
}
