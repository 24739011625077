export default function generateColorVariants(
  hex: string,
  count: number
): string[] {
  function hexToHSL(hex: string): [number, number, number] {
    let r: number = parseInt(hex.substring(1, 3), 16) / 255;
    let g: number = parseInt(hex.substring(3, 5), 16) / 255;
    let b: number = parseInt(hex.substring(5, 7), 16) / 255;

    let max: number = Math.max(r, g, b),
      min: number = Math.min(r, g, b);
    let h: number,
      s: number,
      l: number = (max + min) / 2;

    if (max === min) {
      h = s = 0;
    } else {
      let d: number = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
        default:
          h = 0;
      }
      h /= 6;
    }
    return [h * 360, s, l];
  }

  function hslToHex(h: number, s: number, l: number): string {
    function hueToRGB(p: number, q: number, t: number): number {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    }

    let r: number, g: number, b: number;
    if (s === 0) {
      r = g = b = l;
    } else {
      let q: number = l < 0.5 ? l * (1 + s) : l + s - l * s;
      let p: number = 2 * l - q;
      r = hueToRGB(p, q, h / 360 + 1 / 3);
      g = hueToRGB(p, q, h / 360);
      b = hueToRGB(p, q, h / 360 - 1 / 3);
    }

    return (
      `#${Math.round(r * 255)
        .toString(16)
        .padStart(2, '0')}` +
      `${Math.round(g * 255)
        .toString(16)
        .padStart(2, '0')}` +
      `${Math.round(b * 255)
        .toString(16)
        .padStart(2, '0')}`
    );
  }

  let [h, s, l]: [number, number, number] = hexToHSL(hex);
  let colors: string[] = [];
  let step: number = (0.9 - 0.1) / (count + 1); // Adjust step to spread values more evenly
  for (let i = 1; i <= count; i++) {
    // Start from 1 to avoid including the original color
    let newL: number = Math.min(1, Math.max(0.1, 0.1 + i * step)); // Ensure unique, evenly spaced lightness values
    colors.push(hslToHex(h, s, newL));
  }
  return colors;
}
