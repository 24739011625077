import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  Output,
  EventEmitter,
  HostListener
} from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import {
  NavigationEnd,
  Router,
  NavigationStart,
  ActivatedRoute
} from '@angular/router';
import { NotificationService } from 'src/app/services/notification.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component';
import { AuthService } from '../../services/auth/auth.service';
import { Title } from '@angular/platform-browser';
import { ModalDialogData } from 'src/app/models/modelDialog.model';
import { ConfigService } from 'src/app/initializer/config.service';
import { TermsOfUseComponent } from 'src/app/shared/components/dialog/terms-of-use/terms-of-use.component';
// import { environment } from './environments/environment';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Base64 } from 'js-base64';
import { BreakPointService } from '../../../shared/services/break-point.service';
import { MatSelect } from '@angular/material/select';
@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit, OnChanges {
  @Input() baseData: any;
  @Output() handleLPS = new EventEmitter<string>();
  @Output() handleVehicle = new EventEmitter<string>();

  title: any = 'LP Portal';
  isVisible$ = new BehaviorSubject(true);
  currentGPSLogo: any = { smallUrl: '', bigUrl: '' };
  sessionUser: any = {};
  currentGPS: any;
  selectedFund: any | undefined;
  privileges: any = [];
  selectedInvestor: any;
  selectedMenu: any;
  investors: any[] = [];
  funds: any[] = [];
  menuSubscription: Subscription = new Subscription();
  selectedChildItem: string = '';
  pathSeparator: string = '/';
  newMenuItem: string = '';
  suffixMessage: string = '';
  currentVehicle: any = {};
  isInaccessible: boolean = false;
  isUserHasPreivilege: boolean = false;
  childMenuAllowedItems: Array<string> = [
    'reports',
    'capital-account',
    'settings',
    'documents',
    'insights'
  ];
  menuItems = [
    {
      key: 'viewDashboard',
      value: 'dashboard',
      path: '/dashboard',
      viewValue: 'Dashboard',
      svg: [
        {
          d: 'M21 20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V9.48907C3 9.18048 3.14247 8.88917 3.38606 8.69972L11.3861 2.47749C11.7472 2.19663 12.2528 2.19663 12.6139 2.47749L20.6139 8.69972C20.8575 8.88917 21 9.18048 21 9.48907V20ZM19 19V9.97815L12 4.53371L5 9.97815V19H19Z'
        },
      ],
      imgURL: '../../assets/images/home_icon.svg',
      activeimgURL: '../../assets/images/home_icon.svg'
    },
    {
      key: 'viewInsights',
      value: 'insights',
      path: '/insights',
      viewValue: 'Insights',
      svg: [
        {
          d: 'M18 20V10M12 20V4M6 20V14',
          stroke: "var(--primary-color)",
          strokeWidth: "2",
          strokeLinecap: "round",
          strokeLinejoin: "round"
        },
      ],
      imgURL: '../../assets/images/dashboard_icon.svg',
      activeimgURL: '../../assets/insights-active.png',
      subMenus: [
        {
          key: 'fundMetrics',
          value: 'Fund Metrics',
          path: 'fund-metrics',
          viewValue: 'Fund metrics',
        },
        {
          key: 'portfolioInvestments',
          value: 'Portfolio Investments',
          path: 'portfolio-investments',
          viewValue: 'Portfolio investments',
        },
        {
          key: 'fundInvestments',
          value: 'Fund Investments',
          path: 'fund-investments',
          viewValue: 'Fund investments',
        }
        // {
        //   key: 'PortfolioCompanies',
        //   value: 'Portfolio Companies',
        //   path: 'portfolio-companies',
        //   viewValue: 'Portfolio Companies',
        // }
      ],
      isToggled: false
    },
    {
      key: 'viewCapitalAccount',
      value: 'capital-account',
      path: '/capital-account',
      viewValue: 'Capital account',
      svg: [
        {
          d: 'M12.0004 16C14.2095 16 16.0004 14.2091 16.0004 12C16.0004 9.79086 14.2095 8 12.0004 8C9.79123 8 8.00037 9.79086 8.00037 12C8.00037 14.2091 9.79123 16 12.0004 16ZM21.0049 4.00293H3.00488C2.4526 4.00293 2.00488 4.45064 2.00488 5.00293V19.0029C2.00488 19.5552 2.4526 20.0029 3.00488 20.0029H21.0049C21.5572 20.0029 22.0049 19.5552 22.0049 19.0029V5.00293C22.0049 4.45064 21.5572 4.00293 21.0049 4.00293ZM4.00488 15.6463V8.35371C5.13065 8.017 6.01836 7.12892 6.35455 6.00293H17.6462C17.9833 7.13193 18.8748 8.02175 20.0049 8.3564V15.6436C18.8729 15.9788 17.9802 16.8711 17.6444 18.0029H6.3563C6.02144 16.8742 5.13261 15.9836 4.00488 15.6463Z'
        },
      ],
      imgURL: '../../assets/images/cash_icon.svg',
      activeimgURL: '../../assets/capitalaccount-active.png'
    },
    {
      key: 'viewReports',
      value: 'reports',
      path: '/reports',
      viewValue: 'Reports',
      svg: [
        {
          d: 'M9 2.4578V4.58152C6.06817 5.76829 4 8.64262 4 12C4 16.4183 7.58172 20 12 20C15.3574 20 18.2317 17.9318 19.4185 15H21.5422C20.2679 19.0571 16.4776 22 12 22C6.47715 22 2 17.5228 2 12C2 7.52236 4.94289 3.73207 9 2.4578ZM12 2C17.5228 2 22 6.47715 22 12C22 12.3375 21.9833 12.6711 21.9506 13H11V2.04938C11.3289 2.01672 11.6625 2 12 2ZM13 4.06189V11H19.9381C19.4869 7.38128 16.6187 4.51314 13 4.06189Z'
        }
      ],
      imgURL: '../../assets/images/reports_icon.svg',
      activeimgURL: '../../assets/reports-active.png'
    },
    {
      key: 'viewDocuments',
      value: 'documents',
      path: '/documents',
      viewValue: 'Documents',
      svg: [
        {
          d: 'M9 2.00318V2H19.9978C20.5513 2 21 2.45531 21 2.9918V21.0082C21 21.556 20.5551 22 20.0066 22H3.9934C3.44476 22 3 21.5501 3 20.9932V8L9 2.00318ZM5.82918 8H9V4.83086L5.82918 8ZM11 4V9C11 9.55228 10.5523 10 10 10H5V20H19V4H11Z'
        },
      ],
      imgURL: '../../assets/images/documents_icon.svg',
      activeimgURL: '../../assets/images/documents_icon.svg'
    }
  ];

  tabValue: { [key: string]: string } = {
    viewDashboard: 'dashboard',
    viewInsights: 'insights',
    viewCapitalAccount: 'capital-account',
    viewReports: 'reports',
    viewDocuments: 'documents'
  };
  tabKey: { [key: string]: string } = {
    dashboard: 'viewDashboard',
    insights: 'viewInsights',
    'capital-account': 'viewCapitalAccount',
    reports: 'viewReports',
    documents: 'viewDocuments'
  };
  activePath: string = 'dashboard';
  lpId: string = '';
  vehicleId: string = '';
  activeClassName: string = '';
  lpName: string = '';
  vehicleName: string = '';
  tabName: string = '';
  public currentWindowWidth: number;
  isAdmin: Boolean = false;
  sideMenuopen: boolean = false;
  gpPortalLoginEnabled = false;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private notificationService: NotificationService,
    public dialog: MatDialog,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private config: ConfigService,
    private translateService: TranslateService,
    public breakPointService: BreakPointService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.handleNavigationEnd(event);
      } else if (event instanceof NavigationStart) {
        this.handleNavigationStart(event);
      }
    });

    this.menuSubscription = this.notificationService
      .getClickEvent()
      .subscribe((menuTitle) => {
        this.onSelectedMenu(menuTitle);
      });

    this.currentWindowWidth = window.innerWidth;
  }

  getInitialMenuItem() {
    return (
      this.menuItems.find((menuItem: any) => menuItem.value === this.tabName) ||
      this.menuItems[0]
    );
  }

  getTabName(menuValue: string) {
    if (!menuValue) return menuValue;
    let filteredLp = this.baseData.lps.find((lp: any) => lp.id == this.lpId);
    let filterVehicle = filteredLp?.vehicles.find(
      (vehicle: any) => vehicle.id == this.vehicleId
    );
    let vehiclePrevileges = filterVehicle?.privileges;

    if (!filterVehicle) return menuValue;

    return vehiclePrevileges.find(
      (previlege: string) => previlege == this.tabKey[menuValue]
    )
      ? menuValue
      : this.tabValue[vehiclePrevileges[0]];
  }

  checkValidFund(id : any){
    let vehicleData = this.baseData.vehicles.find(
      (vehicle: any) => vehicle.id === id
    );
    let gpsData = this.baseData.gps.find(
      (gp: any) => gp.id === vehicleData.gpId
    );
    return (vehicleData.active && gpsData.active) ? true : false;
  }

  handleNavigationEnd(event: NavigationEnd) {
    const urlSegments = event.url.split('/');
    this.lpName = urlSegments[1];
    this.lpId = urlSegments[2];
    this.vehicleName = urlSegments[3];
    this.vehicleId = urlSegments[4];
    this.tabName = urlSegments[5];

    if(!this.checkValidFund(this.vehicleId)){
      this.checkValidInitialFund(this.funds)
      this.currentVehicle = this.funds[this.index];
      this.onLPSChange(this.getSelectedInvestor())
      const redirectUrl = `/lp/${this.selectedInvestor.id}/vehicle/${this.currentVehicle.id}/${this.getTabName(this.tabName)}`;
      this.openDialog(true, redirectUrl);
    }

    let isValidMenu = this.menuItems.find(
      (menuItem: any) => menuItem.value === this.tabName
    );
    if (isValidMenu) {
      this.activePath = isValidMenu.value;
    }
    let menuItem = this.getInitialMenuItem();
    this.selectedInvestor = this.baseData.lps.find(
      (item: any) => item.id === this.lpId
    );
    this.selectedInvestor = this.getSelectedInvestor();

    if (this.selectedInvestor) {
      let currentInvestorFunds = this.selectedInvestor.vehicles;

      //code to check if user has previlige access
      if (
        this.vehicleId &&
        currentInvestorFunds &&
        this.filterCurrentVehicle()
      ) {
        let userPreviligeData = currentInvestorFunds.find(
          (item: any) => item.id === this.vehicleId
        )?.privileges;
        this.isUserHasPreivilege = this.checkPrivileges(
          userPreviligeData,
          menuItem
        );


        if (!this.isUserHasPreivilege && this.checkValidFund(this.vehicleId)) {
          const urlRedirect = `lp/${this.selectedInvestor.id}/vehicle/${this.vehicleId
            }/${this.getTabName(this.tabName)}`;
          this.openDialog(true, urlRedirect);
        }
      }

      let filteredFunds = this.filterFunds(currentInvestorFunds);
      this.funds = this.currentFund(filteredFunds, currentInvestorFunds);
      this.currentVehicle = this.filterCurrentVehicle();
    }
    if (
      this.lpName != 'lp' &&
      this.lpName.length > 0 &&
      event.url != '/dashboard'
    ) {
      this.initializeInvestorAndFunds();
      this.currentVehicle = this.filterCurrentVehicle();
      const urlRedirect = `lp/${this.selectedInvestor.id}/vehicle/${this.selectedFund.id}/dashboard`;
      this.openDialog(true, urlRedirect);
    } else if (
      !this.selectedInvestor &&
      this.lpName == 'lp' &&
      this.lpId &&
      this.lpId.length > 0
    ) {
      this.initializeInvestorAndFunds();
      this.checkValidInitialFund(this.funds)
      this.currentVehicle = this.funds[this.index];
      this.vehicleId = this.currentVehicle.id;
      const redirectUrl = `/lp/${this.selectedInvestor.id}/vehicle/${this.currentVehicle.id}/${this.menuItems[0].value}`;
      this.openDialog(true, redirectUrl);
    } else if (
      !this.selectedInvestor &&
      this.lpName == 'lp' &&
      this.lpId == undefined
    ) {
      this.initializeInvestorAndFunds();
      this.checkValidInitialFund(this.funds)
      this.currentVehicle = this.funds[0];
      this.vehicleId = this.currentVehicle.id;
      this.router.navigate([
        `/lp/${this.selectedInvestor.id}/vehicle/${this.currentVehicle.id}/${menuItem.value}`
      ]);
    } else if (
      this.lpName == 'lp' &&
      this.selectedInvestor &&
      this.vehicleName != 'vehicle'
    ) {
      if (this.vehicleName == undefined) {
        this.initializeInvestorAndFunds();
        this.checkValidInitialFund(this.funds)
        this.currentVehicle = this.funds[this.index];
        this.vehicleId = this.currentVehicle.id;
        this.router.navigate([
          `lp/${this.selectedInvestor.id}/vehicle/${this.currentVehicle.id}/dashboard`
        ]);
      } else if (this.vehicleName != 'vehicle' && this.vehicleName) {
        this.initializeInvestorAndFunds();
        this.checkValidInitialFund(this.funds)
          this.currentVehicle = this.funds[this.index];
        this.vehicleId = this.currentVehicle.id;
        const urlRedirect = `lp/${this.selectedInvestor.id}/vehicle/${this.currentVehicle.id}/dashboard`;
        this.openDialog(true, urlRedirect);
      }
    } else if (
      !this.selectedInvestor &&
      this.lpId != undefined &&
      this.lpId.length > 0
    ) {
      this.initializeInvestorAndFunds();
      this.checkValidInitialFund(this.funds)
      this.currentVehicle = this.funds[this.index];
      this.vehicleId = this.currentVehicle.id;
      const urlRedirect = `lp/${this.selectedInvestor.id}/vehicle/${this.currentVehicle.id}/dashboard`;
      this.openDialog(true, urlRedirect);
    } else if (urlSegments.length >= 3) {
      if (isValidMenu && urlSegments.length > 6) {
        if (
          this.tabName == 'dashboard' ||
          (this.tabName == 'reports' && urlSegments.length > 8)
        ) {
          this.router.navigate([
            `lp/${this.selectedInvestor.id}/vehicle/${this.currentVehicle.id}/${menuItem.value}`
          ]);
        }
      }

      if (!this.selectedInvestor && !this.currentVehicle) {
        this.initializeInvestorAndFunds();
        this.checkValidInitialFund(this.funds)
        this.currentVehicle = this.funds[this.index];
        this.vehicleId = this.currentVehicle.id;
        let redirectUrl = `/lp/${this.selectedInvestor.id}/vehicle/${this.currentVehicle.id}/${menuItem.value}`;
        if (this.vehicleId != undefined) {
          this.openDialog(true, redirectUrl);
        } else {
          this.router.navigate([
            `/lp/${this.selectedInvestor.id}/vehicle/${this.currentVehicle.id}/${menuItem.value}`
          ]);
        }
      } else if (!this.selectedInvestor && this.currentVehicle) {
        this.initializeInvestorAndFunds();
        this.checkValidInitialFund(this.funds)
        this.currentVehicle = this.funds[this.index];
        this.vehicleId = this.currentVehicle.id;
        let redirectUrl = `/lp/${this.selectedInvestor.id}/vehicle/${this.currentVehicle.id}/${menuItem.value}`;
        this.openDialog(true, redirectUrl);
      } else if (
        !this.currentVehicle &&
        this.vehicleName == 'vehicle' &&
        this.selectedInvestor
      ) {
        let currentInvestorFunds = this.selectedInvestor.vehicles;
        let filteredFunds = this.filterFunds(currentInvestorFunds);
        this.funds = this.currentFund(filteredFunds, currentInvestorFunds);
        this.checkValidInitialFund(this.funds)
        this.currentVehicle = this.funds[this.index];
        this.vehicleId = this.currentVehicle.id;
        if (this.vehicleId != undefined && this.vehicleName == 'vehicle') {
          const redirectUrl = `/lp/${this.selectedInvestor.id}/vehicle/${this.currentVehicle.id
            }/${this.tabValue[this.currentVehicle.privileges[0]]}`;
          this.openDialog(true, redirectUrl);
        } else {
          this.router.navigate([
            `/lp/${this.selectedInvestor.id}/vehicle/${this.currentVehicle.id}/${this.menuItems[0].value}`
          ]);
        }
      } else if (
        !isValidMenu &&
        this.currentVehicle &&
        this.selectedInvestor &&
        this.tabName &&
        this.tabName.length > 0
      ) {
        let redirectUrl = `/lp/${this.selectedInvestor.id}/vehicle/${this.currentVehicle.id}/${menuItem.value}`;
        this.openDialog(true, redirectUrl);
      } else if (!isValidMenu && !this.tabName) {
        this.router.navigate([
          `/lp/${this.selectedInvestor.id}/vehicle/${this.currentVehicle.id}/${menuItem.value}`
        ]);
      }
    } else {
      this.initializeInvestorAndFunds();
      this.checkValidInitialFund(this.funds)
      this.currentVehicle = this.funds[this.index];
      this.vehicleId = this.currentVehicle.id;
      const menuItem = this.tabName
        ? this.getMenuItem(this.tabName)
        : this.getInitialMenuItem();
      this.router.navigate([
        `lp/${this.selectedInvestor.id}/vehicle/${this.currentVehicle.id}/${menuItem.value}`
      ]);
    }
    if (this.lpId && this.vehicleId) {
      this.selectedInvestor = this.investors.find(
        (investor: any) => investor.id === this.lpId
      );
      this.isAdmin = this.selectedInvestor.canAdministrate;
      let currentInvestorFunds = this.selectedInvestor.vehicles;
      let filteredFunds = this.filterFunds(currentInvestorFunds);
      this.funds = this.currentFund(filteredFunds, currentInvestorFunds);
      this.selectedFund = this.funds.find(
        (fund: any) => fund.id === this.vehicleId
      );
      this.changeTheme(this.currentVehicle);
      this.selectedMenu = this.getMenuItem(this.tabName);
      this.onSelectedMenu(this.selectedMenu);
      this.titleService.setTitle(
        `${this.selectedMenu?.viewValue} - ${this.currentVehicle?.name} - ${this.currentGPS?.name} ${this.title}`
      );
    }
    if (isValidMenu && urlSegments.length > 6 && this.tabName == 'insights') {
      this.menuItems.forEach(item => {
        if ('subMenus' in item && item.path === this.selectedMenu.path) {
          item.isToggled = true;
        }
      });
    }
    this.activeClassName = this.activePath + '-component';
    if (this.activePath === 'default') {
      this.router.navigate(
        [
          `lp/${this.selectedInvestor.id}/vehicle/${this.selectedFund.id}/dashboard`
        ],
        {
          state: {
            lps: this.selectedInvestor,
            vehicle: this.currentVehicle,
            baseData: this.baseData
          }
        }
      );
    }
  }
  handleNavigationStart(event: NavigationStart) {
    const currentRoute = event.url.split('/')[5] || 'dashboard';
    this.newMenuItem = currentRoute === 'account' ? 'profile' : currentRoute;
    this.makeChildItemEmpty();
  }
  index = 0

  checkValidInitialFund(funds : any) {
    for (let i=funds.length-1;i>=0;i--){
      let vehicleData = this.baseData.vehicles.find(
        (vehicle: any) => vehicle.id === funds[i].id
      );
      let gpsData = this.baseData.gps.find(
        (gp: any) => gp.id === vehicleData.gpId
      );
      if(vehicleData.active && gpsData.active){
        this.index = i;
      }
    }
  }


  openTermsOfUse() {
    this.dialog.open(TermsOfUseComponent, {
      data: {
        confirmedTermsOfUse: this.sessionUser.events.confirmedTermsOfUse
      }
    });
  }

  openGpPortal() {
    const gpPortalUrl = this.baseData['gpPortalUrl'];
    if (typeof gpPortalUrl === 'string' && gpPortalUrl.trim() !== '') {
      window.open(gpPortalUrl, '_blank');
    }
  }

  openDialog(event: boolean, redirectUrl: any) {
    if (event) {
      this.isInaccessible = true;
      let dialogRef = this.dialog.open(DialogComponent, {
        data: <ModalDialogData>{
          titleKey: 'Access Denied',
          contentKey: 'access denied',
          positiveMessageKey: 'OK',
          negativeMessageKey: '',
          isValid: '1'
        },
        disableClose: true
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result === '1') {
          this.redirectToValidURL(redirectUrl);
          this.isInaccessible = false;
        }
      });
    } else {
      let dialogRef = this.dialog.open(DialogComponent, {
        data: <ModalDialogData>{
          titleKey: 'settings.logOut',
          contentKey: 'settings.logoutConfirmation',
          positiveMessageKey: 'yesMessage',
          negativeMessageKey: 'noMessage',
          currentGP: this.currentGPS,
          isValid: '0'
        }
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result === '1') {
          this.authService.logout();
        }
      });
    }
  }

  destroyAndReload() {
    this.isVisible$.next(false);
    setTimeout(() => {
      this.isVisible$.next(true);
    }, 0);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.baseData && Object.keys(this.baseData).length > 0) {
      this.investors = this.baseData.lps;
      this.selectedInvestor = this.investors[0];
    }
  }

  shouldHideSubmenu(subItem: any): boolean {
    const hideConditions: Record<string, string> = {
      directInvestmentFund: 'fund-investments',
      fundOfFunds: 'portfolio-investments'
    };
    return hideConditions[this.currentVehicle.type] === subItem.path;
  }
  ngOnInit() {
    this.config.notifyObservable$.subscribe((res) => {
      if (res.refresh) {
        console.log('Refreshing....');
        this.destroyAndReload();
        this.sessionUser = this.config.sessionuser.data;
      }
    });
    this.lpId = this.activatedRoute.snapshot.params['lpId'];
    this.vehicleId = this.activatedRoute.snapshot.params['vehicleId'];
    this.gpPortalLoginEnabled = this.config.sessionuser.data?.gpPortalLoginEnabled || false;
    this.makeChildItemEmpty();

    if (!this.config.sessionuser.error) {
      this.sessionUser = this.config.sessionuser.data;
    }
    if (Object.keys(this.baseData).length > 0) {
      this.investors = this.baseData.lps.sort(function (a: any, b: any) {
        if (a.name && b.name) {
          return a.name.localeCompare(b.name, undefined, {
            sensitivity: 'base'
          });
        }
      });
      this.selectedInvestor = this.investors[0];
      this.isAdmin = this.selectedInvestor.canAdministrate;
      let currentInvestorFunds = this.selectedInvestor.vehicles;
      let filteredFunds = this.baseData.vehicles
        .filter((veh: any) => {
          return currentInvestorFunds.some((f: any) => {
            return f.id === veh.id;
          });
        })
        .sort((a: any, b: any) => {
          if (a.name && b.name) {
            return a.name.localeCompare(b.name, undefined, {
              sensitivity: 'base'
            });
          }
        });
      this.funds = filteredFunds.map((f1: any) => {
        return currentInvestorFunds.find((f2: any) => f1.id === f2.id);
      });
      this.checkValidInitialFund(this.funds)
      this.selectedFund = this.funds[this.index];
      this.currentVehicle = this.baseData.vehicles
        ? this.baseData.vehicles.find(
          (vehicle: any) => vehicle.id === this.selectedFund.id
        )
        : {};
      this.updatePrivileges(this.menuItems);
      this.changeTheme(this.currentVehicle);
      this.selectedMenu = this.menuItems[0];
      if (localStorage.getItem('tpKey')) {
        this.router.navigate([localStorage.getItem('tpKey')]);
        localStorage.removeItem('tpKey');
      }
    }
  }
  @HostListener('window:popstate', ['$event'])
  onPopState(event: any) {
    if (location.href.length < 55) {
      window.location.reload();
    }
  }

  getVehicleName(vehicleOfLP: any) {
    let vehicleData = this.baseData.vehicles.find(
      (vehicle: any) => vehicle.id === vehicleOfLP.id
    );
    let gpsData = this.baseData.gps.find(
      (gp: any) => gp.id === vehicleData.gpId
    );
    if(gpsData.active == false){
      vehicleData.active = false;
    }
    return vehicleData
  }

  onLPSChange(lps: any) {
    this.selectedInvestor = lps;
    let currentInvestorFunds = lps.vehicles;
    let filteredFunds = this.baseData.vehicles
      .filter((veh: any) => {
        return currentInvestorFunds.some((f: any) => {
          return f.id === veh.id;
        });
      })
      .sort((a: any, b: any) => {
        if (a.name && b.name) {
          return a.name.localeCompare(b.name, undefined, {
            sensitivity: 'base'
          });
        }
      });
    this.funds = filteredFunds.map((f1: any) => {
      return currentInvestorFunds.find((f2: any) => f1.id === f2.id);
    });
    this.isAdmin = this.selectedInvestor.canAdministrate;
    this.checkValidInitialFund(this.funds)
    this.onVehicleChange(this.funds[this.index]);
  }

  onVehicleChange(vehicle: any) {
    this.privileges = [];
    this.selectedFund = vehicle;
    this.updatePrivileges(this.menuItems);
    this.currentVehicle = this.baseData.vehicles.find(
      (vehicle: any) => vehicle.id === this.selectedFund.id
    );
    let currentVehicle = this.baseData.vehicles.find(
      (baseDataVehicle: any) => baseDataVehicle.id === vehicle.id
    );
    this.currentGPSLogo = { smallUrl: '', bigUrl: '' };
    this.changeTheme(currentVehicle);
    let titleArr = this.titleService.getTitle().split(' - ');
    this.titleService.setTitle(
      `${titleArr[0]} - ${this.currentVehicle.name} - ${this.currentGPS.name} ${this.title}`
    );
    if(this.activePath === 'insights') {
      this.activePath = this.activePath + '/fund-metrics';
    }
    this.router.navigate(
      [
        `lp/${this.selectedInvestor.id}/vehicle/${this.selectedFund.id}/${this.activePath}`
      ],
      {
        state: {
          lps: this.selectedInvestor,
          vehicle: this.currentVehicle,
          baseData: this.baseData
        }
      }
    );
  }

  navigateToComponent(data: any) {
    this.currentVehicle = this.baseData.vehicles.find(
      (vehicle: any) => vehicle.id === this.selectedFund.id
    );
    this.router.navigate(
      [
        `lp/${this.selectedInvestor.id}/vehicle/${this.selectedFund.id}/${data.path}`
      ],
      {
        state: {
          lps: this.selectedInvestor,
          vehicle: this.currentVehicle,
          baseData: this.baseData
        }
      }
    );
    this.titleService.setTitle(
      `${data.viewValue} - ${this.currentVehicle.name} - ${this.currentGPS.name} ${this.title}`
    );
  }

  navigateToSubmenu(path: string) {
    this.router.navigate([
      `lp/${this.selectedInvestor.id}/vehicle/${this.selectedFund.id}/${path}`,
    ]);
  }

  isSubMenuActive(item: any) {
    // Logic for determining if a sub-menu active
    if (item.subMenus) {
      for (const subItem of item.subMenus) {
        let subMenuPath = `/lp/${this.selectedInvestor.id}/vehicle/${this.selectedFund.id}${item.path}/${subItem.path}`;
        if (this.router.url.startsWith(subMenuPath)) {
          return 'menuItem active-link';
        }
      }
    }
    return 'menuItem';
  }

  changeTheme(vehicle: any) {
    this.currentGPS = this.baseData.gps.find(
      (gp: any) => gp.id === vehicle.gpId
    );
    if (this.currentGPS) {
      setTimeout(() => {
        this.currentGPSLogo = this.currentGPS?.logos;
      }, 400);
      document.documentElement.style.setProperty(
        '--primary-color',
        this.currentGPS.colors.primary
      );
      document.documentElement.style.setProperty(
        '--primary-light-color',
        this.currentGPS.colors.primaryLight
      );
      document.documentElement.style.setProperty(
        '--secondary-color',
        this.currentGPS.colors.secondary
      );
      document.documentElement.style.setProperty(
        '--secondary-light-color',
        this.currentGPS.colors.secondaryLight
      );
    }
  }
  redirectToValidURL(redirectUrl: any) {
    this.router.navigate([redirectUrl]);
  }
  ngOnDestroy() { }

  validRedirectURL() { }
  loadSortedFunds() {
    let currentInvestorFunds = this.selectedInvestor.vehicles;
    let filteredFunds = this.baseData.vehicles
      .filter((veh: any) => {
        return currentInvestorFunds.some((f: any) => {
          return f.id === veh.id;
        });
      })
      .sort((a: any, b: any) => {
        if (a.name && b.name) {
          return a.name.localeCompare(b.name, undefined, {
            sensitivity: 'base'
          });
        }
      });
    this.funds = filteredFunds.map((f1: any) => {
      return currentInvestorFunds.find((f2: any) => f1.id === f2.id);
    });
  }
  getMenuItem(value: string): any {
    return (
      this.menuItems.find((menuItem: any) => menuItem.value === value) ||
      this.menuItems[0]
    );
  }
  filterFunds(currentInvestorFunds: any) {
    return this.baseData.vehicles
      .filter((veh: any) =>
        currentInvestorFunds.some((f: any) => f.id === veh.id)
      )
      .sort((a: any, b: any) => {
        return a.name && b.name
          ? a.name.localeCompare(b.name, undefined, { sensitivity: 'base' })
          : 0;
      });
  }
  currentFund(filteredFunds: any, currentInvestorFunds: any) {
    return filteredFunds.map((f1: any) =>
      currentInvestorFunds.find((f2: any) => f1.id === f2.id)
    );
  }
  filterCurrentVehicle() {
    return this.baseData.vehicles.find(
      (item: any) => item.id == this.vehicleId
    );
  }
  initializeInvestorAndFunds() {
    this.selectedInvestor = this.baseData.lps[0];
    let currentInvestorFunds = this.selectedInvestor.vehicles;
    let filteredFunds = this.filterFunds(currentInvestorFunds);
    this.funds = this.currentFund(filteredFunds, currentInvestorFunds);
  }
  onSelectedMenu(value: any): void {
    this.selectedMenu = value;

    if (value === 'profile' || value === 'settings') {
      this.selectedMenu = { value, viewValue: '', imgURL: '' };
    }

    if (this.childMenuAllowedItems.indexOf(this.selectedMenu.value) !== -1) {
      this.notificationService.notificationSubject.subscribe((message) => {
        setTimeout(() => {
          let messageObj = JSON.parse(message);
          Object.values(messageObj).every((item) => item)
            ? (this.isInaccessible = true)
            : (this.isInaccessible = false);
          if (
            (this.selectedMenu.value === 'documents' ||
              this.selectedMenu.value === 'reports') &&
            messageObj.prefix === 'remove'
          ) {
            this.makeChildItemEmpty();
          } else {
            this.selectedChildItem = messageObj.prefix ? messageObj.prefix : '';
            this.suffixMessage = messageObj.suffix ? messageObj.suffix : '';
          }
        });
      })
    } else {
      this.makeChildItemEmpty();
    }
    if (this.sideMenuopen) {
      this.sideMenuopen = false;
    }
  }
  makeChildItemEmpty() {
    this.selectedChildItem = '';
    this.suffixMessage = '';
  }

  updatePrivileges(menuItems: any) {
    this.privileges = [];
    setTimeout(() => {
      this.privileges = menuItems.filter((item: any) =>
        this.selectedFund.privileges.includes(item.key)
      );
    }, 300);
  }
  getSelectedInvestor(): any {
    return this.baseData.lps.find((item: any) => item.id === this.lpId);
  }
  checkPrivileges(userPreviligeData: string[], menuItem: any): boolean {
    if (!userPreviligeData) return false;
    return userPreviligeData.some((privilege) => privilege === menuItem.key);
  }
  getSecondaryPath(path: string, type: string): any {
    if (type === 'suffix') {
      return path.split(' / ').filter(x => x)
    } else {
      let value: any;
      this.translateService.get(path).subscribe((translation: string) => {
        value = translation.split(' / ').filter(x => x)
      });
      return value;
    }
  }

  breadCrumbNavigate(pathUrl: string, menuName: string, type: string, index: number = 0) {
    if (type === 'primary') {
      if(menuName === 'insights') {
        return;
      }
      this.router.navigate([
        `lp/${this.selectedInvestor.id}/vehicle/${this.selectedFund.id}/${menuName.split(' ').join('-').toLowerCase()}`,
      ], { onSameUrlNavigation: 'reload' });
    }
    if (type === 'suffix') {
      const path = pathUrl
        .split('/')
        .map(x => x.trim())
        .filter(x => x)
        .slice(0, (index + 1))
        .map(x => x.split(' ').join('-'))
        .join('/');
      const endPath = menuName === 'documents' ? Base64.encode(`/Documents/${pathUrl.split('/').map(x => x.trim()).filter(x => x).slice(0, (index + 1)).map(x => x.trim()).join('/')}`) : `/${path}`;
      this.router.navigate([
        `lp/${this.selectedInvestor.id}/vehicle/${this.selectedFund.id}/${menuName.split(' ').join('-').toLowerCase()}/${endPath}`,
      ], { onSameUrlNavigation: 'reload' });
    }
  }
  isActive(item: any, subItem: any): boolean {
    const fullPath = `/lp/${this.selectedInvestor.id}/vehicle/${this.selectedFund.id}/${item.path}/${subItem.path}`;
    return this.router.isActive(fullPath, true);
  }
  closeSidebarIfOpen(event: Event) {
    if (this.sideMenuopen) {
      event.preventDefault();
      event.stopPropagation();
      this.sideMenuopen = false;
    }
  }
}
