// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  hideCapitalAccount: false,
  hideInsightsPortfolio: false,
  frontendBaseUrl: 'https://dev.lp.suite7.io',
  baseUrl: 'https://investor-portal-mock-dev.ace-alternatives.com',
  datahubApiUrl: 'https://dev.api.suite7.io/lp-portal/v1',
  useRealAPI: false,
  authUrl: 'https://auth.ace-alternatives.com/auth/realms/ace-test',
  tokenEndpoint:
    'https://auth.ace-alternatives.com/auth/realms/ace-test/protocol/openid-connect/token',
  clientId: 'investor-portal.app',
  pdfjsExpressViewerLicenseKey: 'H54vX6fcVEDr2EREk2ZS',
  assetBaseUrl: 'https://dev-assets.suite7.io'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
