<div  class="reports-container" *ngIf="showNoReports; else noReports" [ngClass]="{'mob-reports-container' : (breakPointService.isHandset$ | async)}">
  <div class="list-container" *ngIf="showlistView">
    <div class="list-top"></div>
    <mat-card class="list-card">
      <div class="head">

      <div class="year_filter_section">
        <ng-container *ngIf="!isLoading && years?.length || isDropdownChanged  else selectLoader">
          <mat-form-field appearance="fill" class="year_selector">
            <mat-select [(value)]="selectedYear" class="dropdown"
              (selectionChange)="isDropdownChanged = true;onYearChange($event)" #selectYear>
              <mat-option *ngFor="let year of years" [value]="year.value">
                {{ year.viewValue }}
              </mat-option>
            </mat-select>
            <img class="arrow-down" [ngClass]="{ 'open': selectYear.panelOpen}"  src="../../assets/images/arrow-down.svg" matSuffix />
          </mat-form-field>
        </ng-container>
        <ng-container *ngIf="!isLoading && filteredQuarters?.quarters?.length || isDropdownChanged else selectLoader">
          <mat-form-field appearance="fill" class="quarter_selector">
            <mat-select [(value)]="selectedQuarter" class="dropdown"
              (selectionChange)="isDropdownChanged = true;onQuarterChange($event)" #selectQuarter>
              <mat-option *ngFor="let quarter of filteredQuarters.quarters" [value]="quarter">
                {{ quarter.viewValue }}
              </mat-option>
            </mat-select>
            <img class="arrow-down" [ngClass]="{ 'open': selectQuarter.panelOpen}" src="../../assets/images/arrow-down.svg" matSuffix />
          </mat-form-field>
        </ng-container>
      </div>
        
      </div>
      <div class="body" *ngIf="(breakPointService.isHandset$ | async) === false">
        <!-- <mat-selection-list *ngIf="(isHandset$ | async)" [multiple]="false" class="reportsList">
          <ng-container *ngIf="selectedQuarter && selectedQuarter?.value?.toc.length && !isLoading; else tocLoader">
              <mat-list-option *ngFor="let toc of selectedQuarter.value.toc;"
                (click)="onPageChange(toc.page, toc.title)"
                (click)="showPdfViewFunction()"
                [selected]="toc.page === tocToBeHighlighted.page">
                <span>{{ toc.index }} </span><span>{{ toc.title }}</span>
              </mat-list-option>
        </ng-container>
        </mat-selection-list> -->

        <mat-selection-list *ngIf="(breakPointService.isHandset$ | async) === false" [multiple]="false" class="reportsList">
          <ng-container *ngIf=" selectedQuarter && selectedQuarter?.value?.toc.length && !isLoading; else tocLoader">
                <mat-list-option *ngFor="let toc of selectedQuarter.value.toc;"
                (click)="onPageChange(toc.page, toc.title)"
                [selected]="toc.index === tocToBeHighlighted.index">
                <span class="two-circle">
                  <span class="index-circle"></span>
                  <span class="index-text">{{ toc.index }}</span>
                </span>
                <span class="index-title">{{ toc.title }}</span>
              </mat-list-option>
          </ng-container>
        </mat-selection-list>
      </div>
      <ng-container *ngIf="(breakPointService.isHandset$ | async)">
        <ng-container  *ngIf="selectedQuarter && selectedQuarter?.value?.toc.length && !isLoading; else tocLoader">
          <div class="mob-report-List" >
            <div class="report-container" *ngFor="let toc of selectedQuarter.value.toc;" (click)="onPageChange(toc.page, toc.title)">
              <div class="report-badge">{{toc.index}}</div>
              <div class="report-name">{{toc.title}}</div>
              <div class="view-icon">
                <img src="assets/images/view_file.svg" alt="view_icon" height="24px" width="24px">
              </div>
            </div>
          </div>
        </ng-container>
    </ng-container>
    </mat-card>
    <!-- <mat-tab-group dynamicHeight mat-align-tabs="start" (selectedTabChange)="tabChanged($event)">
      <mat-tab class="tab" label="{{ 'translations.reports.quarter' | translate }}"
        [ngClass]="{ 'active-tab': currentTab === 0 }">
        <mat-card class="list-card">
          <div class="head">
            <mat-select [(value)]="selectedYear" class="dropdown" (selectionChange)="onYearChange($event)">
              <mat-option *ngFor="let year of years" [value]="year.value">
                {{ year.viewValue }}
              </mat-option>
            </mat-select>
            <mat-select [(value)]="selectedQuarter" class="dropdown" (selectionChange)="onQuarterChange($event)">
              <mat-option *ngFor="let quarter of quarters" [value]="quarter.value">
                {{ quarter.viewValue }}
              </mat-option>
            </mat-select>
          </div>
          <div class="body">
            <mat-selection-list [multiple]="false" class="reportsList">
              <mat-list-option *ngFor="let report of reportsList; let i = index"
                (click)="onPageChange(report.pageToBeShown, report.value)"
                [selected]="report.pageToBeShown === pageNumber">
                <span>{{ i + 1 }} </span>{{ 'translations.reports.' + report.value | translate }}
              </mat-list-option>
            </mat-selection-list>
          </div>
        </mat-card>
      </mat-tab>
      <mat-tab label="{{ 'translations.reports.annual' | translate }}" [ngClass]="{ 'active-tab': currentTab === 1 }">
        <mat-card class="list-card">
          <div class="head">
            <div class="backButton" [ngClass]="showFolders ? 'disabled' : ''" (click)="handleBack()">
              <span>
                <img src="../../../../assets/arrow-back.svg" />
                {{ 'translations.reports.back' | translate }}
              </span>
            </div>
          </div>
          <div class="body">
            <div *ngIf="showFolders">
              <div class="folderStructure" *ngFor="let folder of folderList; index as i"
                (click)="onFolderNavigation(folder.value)">
                <div>
                  <img src="../../../../assets/folder-icon.svg" />
                  {{ folder.value }}
                </div>
                <div><img src="../../../../assets/arrow.svg" /></div>
              </div>
            </div>
            <div *ngIf="showFiles">
              <mat-selection-list [multiple]="false" class="reportsList">
                <mat-list-option class="fileStructure" *ngFor="let file of fileList; index as i"
                  (click)="showWebViewer(file)">
                  <div>
                    <img src="../../../../assets/file-icon.svg" /> {{ file }}
                  </div>
                </mat-list-option>
              </mat-selection-list>
            </div>
          </div>
        </mat-card>
      </mat-tab>
    </mat-tab-group> -->
  </div>
  <div class="content-container" *ngIf="showPdfView && (breakPointService.isHandset$ | async) === false">
    <button 
      mat-stroked-button
      *ngIf="(breakPointService.isHandset$ | async)"
      class="strockedBackButton"
      (click)="showlistViewFunction()"
    >
      <span>
        <img src="../../../../assets/arrow-back-gray.svg" />
      {{ 'translations.documents.back' | translate }}
      </span>
    </button>
    
    <div class="content-top"></div>
    <ng-container *ngIf="!isLoading else pdfLoader">
      <ng-container *ngIf="filePath else pdfLoader">
        <div class="content-body" [ngClass]="{ previewContainer: !filePath }">
          <div class="viewerTile" [ngClass]="{ hideViewerContainer: !filePath }">
            <div class="viewer">
              <ng-container *ngIf="isVisible$ | async">
                <app-pdf-viewer [pageNumber]="pageNumber" [filePath]="filePath" [fileName]="selectedQuarter.value.title"
                  (handlePageChangeFromChild)="handlePageChangeFromChild($event)"></app-pdf-viewer>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
      <!-- <div class="previewImgContainer" *ngIf="!filePath"  [ngClass]="{ hidePreviewContainer: !!filePath }">
            <div class="PDFIconContainer">
              <svg width="70" height="76" viewBox="0 0 70 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M32.6169 60.1776L31.0214 62.2641L32.8787 64.1213C34.4223 65.665 36.7128 67.8307 39.3984 69.6212C42.0387 71.3813 45.3854 73 49 73C50.7069 73 52.3661 72.4621 53.8076 71.7874C55.275 71.1007 56.7035 70.1834 57.9997 69.2221C60.5862 67.3036 62.8882 65.0276 64.2495 63.4849L65.7984 61.7295L64.4721 59.8004C63.3824 58.2153 61.403 55.9644 58.8376 54.0815C56.2873 52.2097 52.8789 50.5 49 50.5C45.1006 50.5 41.5255 52.3129 38.8188 54.2463C36.0714 56.2087 33.8613 58.5503 32.6169 60.1776Z" stroke="white" stroke-width="6"/>
                <circle cx="48.5" cy="62" r="5.5" fill="white"/>
                <path d="M27.5099 73H10.5099C8.00993 72.8333 3.00993 70.3 3.00993 61.5C3.00993 52.7 3.00993 24.8333 3.00993 12C2.84326 9 4.70993 3 13.5099 3C22.3099 3 40.8432 3 49.0098 3C51.3431 3.16667 56.0098 5.2 56.0098 12C56.0098 18.8 56.0098 34.8333 56.0098 42" stroke="white" stroke-width="6" stroke-linecap="round"/>
                <path d="M11.0078 20.5C11.0078 18.8431 12.351 17.5 14.0078 17.5H45.0078C46.6647 17.5 48.0078 18.8431 48.0078 20.5V20.5C48.0078 22.1569 46.6647 23.5 45.0078 23.5H14.0078C12.351 23.5 11.0078 22.1569 11.0078 20.5V20.5Z" fill="white"/>
                <path d="M11.0078 32.5C11.0078 30.8431 12.351 29.5 14.0078 29.5H45.0078C46.6647 29.5 48.0078 30.8431 48.0078 32.5V32.5C48.0078 34.1569 46.6647 35.5 45.0078 35.5H14.0078C12.351 35.5 11.0078 34.1569 11.0078 32.5V32.5Z" fill="white"/>
                <path d="M11.0078 44.5C11.0078 42.8431 12.351 41.5 14.0078 41.5H30.0078C31.6647 41.5 33.0078 42.8431 33.0078 44.5V44.5C33.0078 46.1569 31.6647 47.5 30.0078 47.5H14.0078C12.351 47.5 11.0078 46.1569 11.0078 44.5V44.5Z" fill="white"/>
                </svg>
            </div>
            <p class="selectMessage">No document found.</p>
          </div> -->
    </ng-container>
  </div>
</div>
<ng-container *ngIf="(breakPointService.isHandset$ | async)">
  <mat-sidenav-container class="task-details-container" hasBackdrop="true"  *ngIf="openPdfSideBar && showPdfView">
    <mat-sidenav #drawer mode="over" position="start" role="dialog" class="full-screen-sidenav" 
    [opened]=" openPdfSideBar ? true : false">
      <div class="pdf-container">
        <div class="pdf-header">
          <img src="../../../assets/images/close_icon.svg" height="24px" width="24px" alt="close" class="image"
          (click)="drawer.close();closepdfView()"/>
          <div class="pdf-name">
            {{selectedQuarter.value.title}}
          </div>
          <img src="../../../assets/images/download_icon.svg" height="24px" width="24px" alt="download" (click)="downloadPdf()"/>
        </div>
        <div class="margin-pdf"></div>
        <ng-container *ngIf="!isLoading; else mobPdfLoader">
          <div class="viewerTile" *ngIf="filePath">
            <div class="viewer">
              <ng-container *ngIf="(isVisible$ | async) && filePath">
                <app-pdf-viewer [pageNumber]="pageNumber" [filePath]="filePath" [fileName]="selectedQuarter.value.title" [disableHeader] = true
                    (handlePageChangeFromChild)="handlePageChangeFromChild($event)"></app-pdf-viewer>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </div>
    </mat-sidenav>
  </mat-sidenav-container>
</ng-container>

<ng-template #noReports>
  <div class="no-report-wrapper" [ngClass]="{'mob-no-report-wrapper' : (breakPointService.isHandset$ | async)}">
    <div class="no-report-container">
        <div class="icon">
            <img src="../../assets/images/reports_icon.svg" alt="icon" height="24px" width="24px">
        </div>
        <div class="text-area">
            <div class="main">No reports available</div>
            <div class="sub-text">Looks like there are no reports available for now. Please check again later
            </div>
        </div>
    </div>
  </div>
</ng-template>

<ng-template #pdfLoader>
  <ngx-skeleton-loader count="1" appearance="line" [theme]="{
    'width': '100%',
    'height': 'calc(100% -  42px)',
    'margin': '0px'
  }"></ngx-skeleton-loader>
</ng-template>

<ng-template #selectLoader>
  <ngx-skeleton-loader class="select-loader" count="1" appearance="line" [theme]="{
    'width': '100%',
    'height': '38px'
  }"></ngx-skeleton-loader>
</ng-template>

<ng-template #tocLoader>
  <div class="tocLoader">
    <ngx-skeleton-loader *ngFor="let loader of [1, 2, 3 ,4 ,5 ,6 ,7, 8]" class="toc-skeleton" count="1" appearance="line" [theme]="{
      'margin': '0.6em 0',
      'height': '25px'
    }"></ngx-skeleton-loader>
  </div>
</ng-template>
<ng-template #mobPdfLoader>
  <div class="viewerTile">
    <ngx-skeleton-loader count="1" appearance="line" [theme]="{
      width: '100%',
      height: '95%',
      margin: '0px',
      padding: '24px 0 24px 0'
    }"></ngx-skeleton-loader>
  </div>
</ng-template>
<ng-template #downloadPdfLoader>
  <ngx-skeleton-loader count="1" appearance="line" [theme]="{
    width: '30px',
    height: '24px',
  }"></ngx-skeleton-loader>
</ng-template>