import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { ApiEndpointsService } from '../core/services/api-endpoints.service';
import { catchError, delay, map } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { LanguagesService } from '../services/languages.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../core/services/auth/auth.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  response: any;

  constructor(
    private http: HttpClient,
    private apiEndpointsService: ApiEndpointsService,
    private userService: UserService,
    public translate: TranslateService,
    private authService: AuthService,
    private router: Router
  ) { }

  sessionuser: any;
  baseData: any;
  public notify = new BehaviorSubject<any>('');
  notifyObservable$ = this.notify.asObservable();

  public async notifyOther(data: any) {
    if (data) {
      await this.fetchuserData();
      this.notify.next(data);
    }
  }

  async init(): Promise<void> {
    // const pathname = window.location.pathname;
    try {
      let isRefresh = true;
      if (!this.authService.isAuthenticated()) {
        isRefresh = false;
      }
      await this.authService.authenticate();
      await this.fetchuserData(isRefresh);
      await this.fetchbaseData();
    } catch (error: any) {
      if (error.statusCode == 403) {
        this.sessionuser = {
          error: 'ACCESS_DENIED'
        };
      }
    }
  }

  async fetchuserData(isRefresh = false) {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.apiEndpointsService.getSessionUser(isRefresh))
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return throwError(() => err.error || 'Server error');
          })
        )
        .subscribe(
          (data) => {
            this.sessionuser = JSON.parse(JSON.stringify(data));
            let userLanguage = ['en', 'de'].includes(
              this.sessionuser.data.state.lang.toLowerCase()
            )
              ? this.sessionuser.data.state.lang.toLowerCase()
              : 'en';
            this.translate.use(userLanguage);
            this.userService.setUserConfig(
              `${this.sessionuser.data.firstName} ${this.sessionuser.data.lastName}`,
              userLanguage,
              this.sessionuser.data.id
            );
            resolve(true);
          },
          (error) => {
            if (error.statusCode == 403) {
              this.sessionuser = {
                error: 'ACCESS_DENIED'
              };
            }
            this.translate.use('en');
            resolve(true);
          }
        );
    });
  }

  async fetchbaseData() {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.apiEndpointsService.getBaseData())
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return throwError(() => err.error || 'Server error');
          })
        )
        .subscribe(
          (data) => {
            this.baseData = JSON.parse(JSON.stringify(data));
            if (this.baseData.data.lps.length == 0) {
              this.sessionuser = {
                error: 'ACCESS_DENIED'
              };
            }
            resolve(true);
          },
          (error) => {
            if (error.statusCode == 403) {
              this.baseData = {
                error: 'ACCESS_DENIED'
              };
            }
            resolve(true);
          }
        );
    });
  }

}
