<ng-container *ngIf="(breakPointService.isHandset$ | async) === false">
  <div class="metrics-container">
    <div class="metric-header">
      {{ 'translations.dashboard.calculationsMultiples.title' | translate }}
      <mat-dialog-actions>
        <button mat-button mat-dialog-close class="close-icon-btn">
          <mat-icon class="close-icon" color="warn">close</mat-icon>
        </button>
      </mat-dialog-actions>
    </div>
    <div mat-dialog-content class="popup-container" [ngClass]="{'tablet-popup-container' : (breakPointService.isTablet$ | async)}">
      <ng-container *ngIf="(breakPointService.isTablet$ | async) === false">
        <mat-card class="metrics-card">
          <mat-card-header>
            {{ 'translations.dashboard.calculationsMultiples.metrics' | translate }}
          </mat-card-header>
          <mat-card-content>
            <div *ngFor="let e of data.metricData" class="metrics-container">
              <div class="metrics-left">
                <span class="circle-text">
                  <p>{{ e.char }}</p>
                </span>
              </div>
              <div class="metrics-right">
                <p class="metricTitle">{{
                  'translations.dashboard.metric.' + e.metricTitle | translate
                }}</p
                >
                <label class="metricDiscription">{{
                  'translations.dashboard.metric.' + e.metricDiscription | translate
                }}</label>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </ng-container>
      <ng-container *ngIf="(breakPointService.isTablet$ | async)">
        <mat-card class="metrics-card-tablet">
          <mat-card-header class="tab-matrix-header">
            {{ 'translations.dashboard.calculationsMultiples.metrics' | translate }}
          </mat-card-header>
          <mat-card-content class="tablet-metrix-conytent">
            <ng-container *ngFor="let e of data.metricData">
              <div class="metrics-container">
                <div class="metrics-left">
                  <span class="circle-text">
                    <p>{{ e.char }}</p>
                  </span>
                </div>
                <div class="metrics-right">
                  <p class="metricTitle">{{
                    'translations.dashboard.metric.' + e.metricTitle | translate
                  }}</p
                  >
                  <label class="metricDiscription">{{
                    'translations.dashboard.metric.' + e.metricDiscription | translate
                  }}</label>
                </div>
              </div>
            </ng-container>
          </mat-card-content>
        </mat-card>
      </ng-container>
  
      <mat-card class="calculations-card">
        <mat-card-content>
          <div class="calculations-container">
            <div class="metrics-multiples">
              <div class="metrics-title">
                {{
                  'translations.dashboard.calculationsMultiples.multiples'
                    | translate
                }}
              </div>
  
              <div class="container">
                <div class="darkGary border-top-left">
                  {{
                    'translations.dashboard.calculationsMultiples.moic'
                      | translate
                  }}
                </div>
                <div class="formula border-top-right">
                  <span class="intial-symbol">(</span>
                  <span class="small-circle-text">
                    <p>E</p>
                  </span>
                  +
                  <span class="small-circle-text">
                    <p>F</p>
                  </span>
                  ) /
                  <span class="small-circle-text">
                    <p>D</p>
                  </span>
                </div>
                <div class="darkGary">
                  {{
                    'translations.dashboard.calculationsMultiples.realizedMoic'
                      | translate
                  }}
                </div>
                <div class="formula">
                  <span class="intial-symbol"></span>
                  <span class="small-circle-text">
                    <p>E</p>
                  </span>
                  /
                  <span class="small-circle-text">
                    <p>D</p>
                  </span>
                </div>
                <div class="darkGary unrealized-moic">
                  {{
                    'translations.dashboard.calculationsMultiples.unRealizedMoic'
                      | translate
                  }}
                </div>
                <div class="formula">
                  <span class="intial-symbol"></span>
                  <span class="small-circle-text">
                    <p>F</p>
                  </span>
                  /
                  <span class="small-circle-text">
                    <p>D</p>
                  </span>
                </div>
                <div class="darkGary">
                  {{
                    'translations.dashboard.calculationsMultiples.grossTvpi'
                      | translate
                  }}
                </div>
                <div class="formula">
                  <span class="intial-symbol">(</span>
                  <span class="small-circle-text">
                    <p>E</p>
                  </span>
                  +
                  <span class="small-circle-text">
                    <p>F</p>
                  </span>
                  ) /
                  <span class="small-circle-text">
                    <p>A</p>
                  </span>
                </div>
                <div class="gary">
                  {{
                    'translations.dashboard.calculationsMultiples.netTvpi'
                      | translate
                  }}
                </div>
                <div class="formula">
                  <span class="intial-symbol">(</span>
                  <span class="small-circle-text">
                    <p>B</p>
                  </span>
                  +
                  <span class="small-circle-text">
                    <p>C</p>
                  </span>
                  ) /
                  <span class="small-circle-text">
                    <p>A</p>
                  </span>
                </div>
                <div class="gary">
                  {{
                    'translations.dashboard.calculationsMultiples.rvpi'
                      | translate
                  }}
                </div>
                <div class="formula">
                  <span class="intial-symbol"></span>
                  <span class="small-circle-text">
                    <p>C</p>
                  </span>
                  /
                  <span class="small-circle-text">
                    <p>A</p>
                  </span>
                </div>
                <div class="gary border-bottom-left">
                  {{
                    'translations.dashboard.calculationsMultiples.dpi' | translate
                  }}
                </div>
                <div class="formula border-bottom-right">
                  <span class="intial-symbol"></span>
                  <span class="small-circle-text">
                    <p>B</p>
                  </span>
                  /
                  <span class="small-circle-text">
                    <p>A</p>
                  </span>
                </div>
              </div>
            </div>
            <div class="metrics-irr">
              <div class="metrics-title">
                {{ 'translations.dashboard.calculationsIRR.irr' | translate }}
              </div>
  
              <div class="outer-wrapper">
                <div class="wrapper">
                  <div class="column">
                    <div class="darkGary border-top-left">
                      {{
                        'translations.dashboard.calculationsIRR.grossirr'
                          | translate
                      }}
                    </div>
                    <div class="darkGary">
                      {{
                        'translations.dashboard.calculationsIRR.grossRealizedIrr'
                          | translate
                      }}
                    </div>
                    <div class="gary">
                      {{
                        'translations.dashboard.calculationsIRR.netIrr' | translate
                      }}
                    </div>
                    <div class="gary border-bottom-left">
                      {{
                        'translations.dashboard.calculationsIRR.netRealizedirr'
                          | translate
                      }}
                    </div>
                  </div>
                  <div class="column">
                    <div class="darkGary darkGary-flow">
                      {{
                        'translations.dashboard.calculationsIRR.outflows'
                          | translate
                      }}
                    </div>
                    <div class="darkGary darkGary-flow">
                      {{
                        'translations.dashboard.calculationsIRR.inflows' | translate
                      }}
                    </div>
                    <div class="darkGary darkGary-flow">
                      {{
                        'translations.dashboard.calculationsIRR.outflows'
                          | translate
                      }}
                    </div>
                    <div class="darkGary darkGary-flow">
                      {{
                        'translations.dashboard.calculationsIRR.inflows' | translate
                      }}
                    </div>
                    <div class="gary darkGary-flow">
                      {{
                        'translations.dashboard.calculationsIRR.outflows'
                          | translate
                      }}
                    </div>
                    <div class="gary darkGary-flow">
                      {{
                        'translations.dashboard.calculationsIRR.inflows' | translate
                      }}
                    </div>
                    <div class="gary darkGary-flow">
                      {{
                        'translations.dashboard.calculationsIRR.outflows'
                          | translate
                      }}
                    </div>
                    <div class="gary darkGary-flow">
                      {{
                        'translations.dashboard.calculationsIRR.inflows' | translate
                      }}
                    </div>
                  </div>
                  <div class="column column-formula">
                    <div class="formula border-top-right">
                      <span class="small-circle-text">
                        <p>D</p>
                      </span>
                      or
                      <span class="small-circle-text">
                        <p>A</p>
                      </span>
                    </div>
                    <div class="formula">
                      <span class="small-circle-text">
                        <p>E</p>
                      </span>
                      &
                      <span class="small-circle-text">
                        <p>F</p>
                      </span>
                    </div>
                    <div class="formula">
                      <span class="small-circle-text">
                        <p>D</p>
                      </span>
                      or
                      <span class="small-circle-text">
                        <p>A</p>
                      </span>
                    </div>
                    <div class="formula">
                      <span class="small-circle-text">
                        <p>E</p>
                      </span>
                    </div>
                    <div class="formula">
                      <span class="small-circle-text">
                        <p>A</p>
                      </span>
                    </div>
                    <div class="formula">
                      <span class="small-circle-text">
                        <p>B</p>
                      </span>
                      or
                      <span class="small-circle-text">
                        <p>C</p>
                      </span>
                    </div>
                    <div class="formula">
                      <span class="small-circle-text">
                        <p>A</p>
                      </span>
                    </div>
                    <div class="formula border-bottom-right">
                      <span class="small-circle-text">
                        <p>B</p>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="realized-unrealized">
                  <div class="realized">
                    <span class="investmentTiles realized"></span>
                    <span> Gross </span>
                  </div>
                  <div class="unrealized">
                    <span class="investmentTiles unrealized"></span>
                    <span> Net </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</ng-container>


<ng-container *ngIf="(breakPointService.isHandset$ | async)">
  <div class="mob-metrics-container">
    <div class="metric-header-mob">
      {{ 'translations.dashboard.calculationsMultiples.title' | translate }}
      <mat-dialog-actions>
        <button mat-button mat-dialog-close class="close-icon-btn">
          <mat-icon class="close-icon" color="warn">close</mat-icon>
        </button>
      </mat-dialog-actions>
    </div>
    <div mat-dialog-content class="popup-container">
      <mat-card class="metrics-card">
        <mat-card-header class="matrics-header">
          {{ 'translations.dashboard.calculationsMultiples.metrics' | translate }}
        </mat-card-header>
        <mat-card-content>
          <div *ngFor="let e of data.metricData" class="metrics-container">
            <div class="metrics-left">
              <span class="circle-text">
                <p>{{ e.char }}</p>
              </span>
            </div>
            <div class="metrics-right">
              <p class="metricTitle">{{
                'translations.dashboard.metric.' + e.metricTitle | translate
              }}</p
              >
              <label class="metricDiscription">{{
                'translations.dashboard.metric.' + e.metricDiscription | translate
              }}</label>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
  
      <mat-card class="calculations-card">
        <mat-card-content>
          <div class="calculations-container">
            <div class="metrics-multiples">
              <div class="metrics-title">
                {{
                  'translations.dashboard.calculationsMultiples.multiples'
                    | translate
                }}
              </div>
              <div class="multiple-container">
                <div class="multiple-row">
                  <div class="multiple-text">{{
                    'translations.dashboard.calculationsMultiples.moic'
                      | translate
                  }}</div>
                  <div class="multiple-symbols">
                    <div class="intial-symbol">( </div><div class="text-circle">E</div> + <div class="text-circle">F</div> ) / <div class="text-circle">D</div>
                  </div>
                </div>
                <div class="multiple-row">
                  <div class="multiple-text">{{
                    'translations.dashboard.calculationsMultiples.realizedMoic'
                      | translate
                  }}</div>
                  <div class="multiple-symbols">
                    <div class="intial-symbol"> </div><div class="text-circle">E</div> / <div class="text-circle">D</div>
                  </div>
                </div>
                <div class="multiple-row">
                  <div class="multiple-text">{{
                    'translations.dashboard.calculationsMultiples.unRealizedMoic'
                      | translate
                  }}</div>
                  <div class="multiple-symbols">
                    <div class="intial-symbol"> </div><div class="text-circle">F</div> / <div class="text-circle">D</div>
                  </div>
                </div>
                <div class="multiple-row">
                  <div class="multiple-text">{{
                    'translations.dashboard.calculationsMultiples.grossTvpi'
                      | translate
                  }}</div>
                  <div class="multiple-symbols">
                    <div class="intial-symbol">( </div><div class="text-circle">E</div> + <div class="text-circle">F</div> ) / <div class="text-circle">A</div>
                  </div>
                </div>
                <div class="multiple-row">
                  <div class="multiple-text text-default-color">{{
                    'translations.dashboard.calculationsMultiples.netTvpi'
                      | translate
                  }}</div>
                  <div class="multiple-symbols">
                    <div class="intial-symbol">( </div><div class="text-circle">B</div> + <div class="text-circle">C</div> ) / <div class="text-circle">A</div>
                  </div>
                </div>
                <div class="multiple-row">
                  <div class="multiple-text text-default-color">{{
                    'translations.dashboard.calculationsMultiples.rvpi'
                      | translate
                  }}</div>
                  <div class="multiple-symbols">
                    <div class="intial-symbol"> </div><div class="text-circle">C</div> / <div class="text-circle">A</div>
                  </div>
                </div>
                <div class="multiple-row">
                  <div class="multiple-text text-default-color">{{
                    'translations.dashboard.calculationsMultiples.dpi' | translate
                  }}</div>
                  <div class="multiple-symbols">
                    <div class="intial-symbol"> </div><div class="text-circle">B</div> / <div class="text-circle">A</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="metrics-irr">
              <div class="metrics-title">
                {{ 'translations.dashboard.calculationsIRR.irr' | translate }}
              </div>
  
              <div class="outer-wrapper">
                <div class="wrapper">
                  <div class="column">
                    <div class="darkGary border-top-left">
                      {{
                        'translations.dashboard.calculationsIRR.grossirr'
                          | translate
                      }}
                    </div>
                    <div class="darkGary">
                      {{
                        'translations.dashboard.calculationsIRR.grossRealizedIrr'
                          | translate
                      }}
                    </div>
                    <div class="gary">
                      {{
                        'translations.dashboard.calculationsIRR.netIrr' | translate
                      }}
                    </div>
                    <div class="gary border-bottom-left">
                      {{
                        'translations.dashboard.calculationsIRR.netRealizedirr'
                          | translate
                      }}
                    </div>
                  </div>
                  <div class="column">
                    <div class="darkGary darkGary-flow">
                      {{
                        'translations.dashboard.calculationsIRR.outflows'
                          | translate
                      }}
                    </div>
                    <div class="darkGary darkGary-flow">
                      {{
                        'translations.dashboard.calculationsIRR.inflows' | translate
                      }}
                    </div>
                    <div class="darkGary darkGary-flow">
                      {{
                        'translations.dashboard.calculationsIRR.outflows'
                          | translate
                      }}
                    </div>
                    <div class="darkGary darkGary-flow">
                      {{
                        'translations.dashboard.calculationsIRR.inflows' | translate
                      }}
                    </div>
                    <div class="gary darkGary-flow">
                      {{
                        'translations.dashboard.calculationsIRR.outflows'
                          | translate
                      }}
                    </div>
                    <div class="gary darkGary-flow">
                      {{
                        'translations.dashboard.calculationsIRR.inflows' | translate
                      }}
                    </div>
                    <div class="gary darkGary-flow">
                      {{
                        'translations.dashboard.calculationsIRR.outflows'
                          | translate
                      }}
                    </div>
                    <div class="gary darkGary-flow">
                      {{
                        'translations.dashboard.calculationsIRR.inflows' | translate
                      }}
                    </div>
                  </div>
                  <div class="column column-formula">
                    <div class="formula border-top-right">
                      <span class="small-circle-text">
                        <p>D</p>
                      </span>
                      or
                      <span class="small-circle-text">
                        <p>A</p>
                      </span>
                    </div>
                    <div class="formula">
                      <span class="small-circle-text">
                        <p>E</p>
                      </span>
                      &
                      <span class="small-circle-text">
                        <p>F</p>
                      </span>
                    </div>
                    <div class="formula">
                      <span class="small-circle-text">
                        <p>D</p>
                      </span>
                      or
                      <span class="small-circle-text">
                        <p>A</p>
                      </span>
                    </div>
                    <div class="formula">
                      <span class="small-circle-text">
                        <p>E</p>
                      </span>
                    </div>
                    <div class="formula">
                      <span class="small-circle-text">
                        <p>A</p>
                      </span>
                    </div>
                    <div class="formula">
                      <span class="small-circle-text">
                        <p>B</p>
                      </span>
                      or
                      <span class="small-circle-text">
                        <p>C</p>
                      </span>
                    </div>
                    <div class="formula">
                      <span class="small-circle-text">
                        <p>A</p>
                      </span>
                    </div>
                    <div class="formula border-bottom-right">
                      <span class="small-circle-text">
                        <p>B</p>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="realized-unrealized">
            <div class="realized">
              <span class="investmentTiles realized"></span>
              <span> Gross </span>
            </div>
            <div class="unrealized">
              <span class="investmentTiles unrealized"></span>
              <span> Net </span>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</ng-container>
