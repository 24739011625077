<div class="insights" *ngFor="let fund of fundMetrics" [id]="fund.labelKey" [ngClass]="{'isHandset': isHandset$ | async, 'isTablet': isTablet$ | async}">
    <div class="insights-title">
        {{ fundMetricsSegments[fund.labelKey] }}
    </div>
    <div class="insight-container" [ngClass]="{ 
        'isOneColumnView': isOneColumnView$ | async, 
        'isTwoColumnView': !(isOneColumnView$ | async) 
      }">
        <div *ngFor="let box of fund.boxes" class="insights-card insight-grid">
            <mat-card class="insights-card insight-item">
                <div class="card-container">
                    <div class="cardHead">
                        <div class="cardHeadLeft">
                            {{ box.labelKey }}
                        </div>
                    </div>
                    <div *ngFor="let view of box.views" class="cardBody">
                        <div *ngIf="view.type === 'chart'" class="insight-head fundMetrics">
                            <div *ngFor="let kpi of view.kpis" class="item">
                                <p *ngIf="kpi.type !== 'currency'; else currency" class="value">
                                    {{ convertCustomValue(kpi) }}{{ kpi.unit }}
                                </p>
                                <ng-template #currency>
                                    <p class="value">
                                        {{ convertCurrency(kpi) }}
                                    </p>
                                </ng-template>
                                <p class="title">{{ kpi.labelKey }}</p>
                            </div>
                        </div>
                        <div class="insight-chart">
                            <app-chart *ngIf="view.type === 'chart'; else table" [chartData]="view.data"
                                [legendColors]="legendColors"></app-chart>
                            <ng-template #table>
                                <div class="insight-table">
                                    <div *ngFor="let row of view.data.rows" class="row">
                                        <div class="title">{{ row.labelKey }}</div>
                                        <div class="value" [ngClass]="
                        selectedVehicle?.negativeNumbers !== 'parentheses'
                          ? ''
                          : row.value < 0
                          ? 'negativeValue'
                          : 'nonNegativeValue'
                      ">
                                            {{ convertCurrency(row) }}
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                        <div *ngIf="view.data.data" class="insight-legends">
                            <div *ngFor="let dataset of view.data.data.datasets; let i = index">
                                <span class="legend-tile" [ngStyle]="{
                    'background-color':
                      dataset.label !== 'Exits' ? legendColors[i] : '#9ba6b0'
                  }"></span><span class="legend-title">{{ dataset.label }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card>
        </div>
    </div>
</div>