<div class="insights" [ngClass]="{'isHandset': isHandset$ | async, 'isTablet': isTablet$ | async}">
  <div class="sections-container" *ngIf="insightsTab === 'fund-metrics' && fundMetrics.length;">
    <div class="section-tab" *ngFor="let segment of fundMetrics;let i = index">
      <span [ngClass]="{ 'active': activeTab === segment.labelKey || (activeTab === '' && i === 0) }" (click)="scrollToSection(segment.labelKey)">{{ fundMetricsSegments[segment.labelKey] }}</span>
    </div>  
  </div>
  <div class="sections-container" *ngIf="insightsTab === 'portfolio-investments' && portfolioInvestments.length">
    <div class="section-tab" *ngFor="let segment of portfolioInvestments; let i = index">
      <span [ngClass]="{ 'active': activeTab === segment.labelKey || (activeTab === '' && i === 0) }" (click)="scrollToSection(segment.labelKey)">{{ portFolioInvestmentSegments[segment.labelKey] }}</span>
    </div>   
  </div>
   
  <div class="bottom-bar"></div>
      <div class="tab fund-metrics" [ngClass]="{ 'tab-no-data': !fundMetrics.length && !isLoading, 'tab-height': fundMetrics.length }" *ngIf="insightsTab === 'fund-metrics'" (scroll)="onScroll(fundMetrics)">
        <div *ngIf="!isLoading; else chartLoader">
          <ng-container *ngIf="fundMetrics.length; else noRecord">
            <div class="category net-performance">
              <app-fund-metrics [fundMetrics]="fundMetrics" [fundMetricsSegments]="fundMetricsSegments"
                [legendColors]="legendColors" [selectedVehicle]="selectedVehicle">
              </app-fund-metrics>
            </div>
          </ng-container>
        </div>
      </div>

      <div class="">
        <div class="tab portfolio-investments" [ngClass]="{ 'tab-no-data': !portfolioInvestments.length && !isLoading, 'tab-height': portfolioInvestments.length }" *ngIf="insightsTab === 'portfolio-investments'" (scroll)="onScroll(portfolioInvestments)">
          <div *ngIf="!isLoading; else chartLoader">
            <ng-container *ngIf="portfolioInvestments.length; else noRecord">
              <div class="category capital-deployment">
                <app-portfolio-investment [selectedVehicle]="selectedVehicle" [legendColors]="legendColors"
                  [portfolioInvestments]="portfolioInvestments" [portFolioInvestmentSegments]="portFolioInvestmentSegments"
                  [selectedInitialAndFollowOnInvestments]="selectedInitialAndFollowOnInvestments"
                  [selectedInitialInvestmentPacing]="selectedInitialInvestmentPacing"
                  [selectedFollowOnInvestmentPacing]="selectedFollowOnInvestmentPacing"
                  [selectedAverageHoldingPeriod]="selectedAverageHoldingPeriod"
                  [isInitialVsFollowOnUpdated$]="isInitialVsFollowOnUpdated$"
                  [isInitialPacingUpdated$]="isInitialPacingUpdated$" [isFollowOnPacingUpdated$]="isFollowOnPacingUpdated$"
                  [isAverageHoldingPeriodUpdated$]="isAverageHoldingPeriodUpdated$"
                  (viewChangeEvent)="viewChange($event)"></app-portfolio-investment>
              </div>
            </ng-container>
          </div>
        </div>
      </div> 
      
      </div>
      <div class="">
        <div class="tab portfolio-investments" [class.tab-no-data]="!fundInvestments.length" *ngIf="insightsTab === 'fund-investments'" (scroll)="onScroll(portfolioInvestments)">
          <div *ngIf="!isLoading; else chartLoader">
            <ng-container *ngIf="fundInvestments.length; else noRecord">
              <div class="category capital-deployment">
                <app-portfolio-investment [selectedVehicle]="selectedVehicle" [legendColors]="legendColors"
                  [portfolioInvestments]="fundInvestments" [portFolioInvestmentSegments]="portFolioInvestmentSegments"
                  [selectedInitialAndFollowOnInvestments]="selectedInitialAndFollowOnInvestments"
                  [selectedInitialInvestmentPacing]="selectedInitialInvestmentPacing"
                  [selectedFollowOnInvestmentPacing]="selectedFollowOnInvestmentPacing"
                  [selectedAverageHoldingPeriod]="selectedAverageHoldingPeriod"
                  [isInitialVsFollowOnUpdated$]="isInitialVsFollowOnUpdated$"
                  [isInitialPacingUpdated$]="isInitialPacingUpdated$" [isFollowOnPacingUpdated$]="isFollowOnPacingUpdated$"
                  [isAverageHoldingPeriodUpdated$]="isAverageHoldingPeriodUpdated$"
                  (viewChangeEvent)="viewChange($event)"></app-portfolio-investment>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    <ng-container *ngIf="insightsTab === 'portfolio-companies'">
      <div class="tab portfolio-companies" *ngIf="hideInsights">
        <div class="portfolio-companies-table">
          <div class="portfolio-companies-card">
            <app-portfolio-companies
              (convertPortfolioValues)="convertPortfolioValues($event)"
            ></app-portfolio-companies>
          </div>
        </div>
      </div>
    </ng-container>
<!-- </div> -->
<ng-template #noRecord>
  <div class="no-insights-wrapper">
    <div class="no-insights-container">
      <div class="icon">
        <img src=".../../../assets/images/no-tasks-icon.svg" alt="icon">
      </div>
      <div class="text-area">
        <div class="main">No data available</div>
        <div class="sub-text">Looks like there is no data available for now. Please check again later
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #chartLoader>
  <div class="loader-card" [ngClass]="{ 
    'isOneColumnView': isOneColumnView$ | async, 
    'isTwoColumnView': !(isOneColumnView$ | async) 
  }">
    <div *ngFor="let box of [1, 2, 3, 4, 5, 6]" class="insight-grid">
      <!------------>
      <mat-card class="insights-card insight-item">
        <div class="loader-container card-container">
          <div class="cardHead">
            <div class="cardHeadLeft">
              <ngx-skeleton-loader
                count="1"
                appearance="line"
                [theme]="{
                  width: '150px',
                  marginBottom: '0px'
                }"
              ></ngx-skeleton-loader>
            </div>
          </div>
          <div class="loader-head">
            <div class="kpi-loader" *ngFor="let kpi of [0, 1, 2]">
              <ngx-skeleton-loader
                count="1"
                appearance="line"
                [theme]="{
                  width: '90%',
                  height: '18px',
                  float: 'right'
                }"
              ></ngx-skeleton-loader>
              <ngx-skeleton-loader
                count="1"
                appearance="line"
                [theme]="{
                  width: '70%',
                  height: '15px',
                  float: 'right'
                }"
              ></ngx-skeleton-loader>
            </div>
          </div>
          <div class="loader-body">
            <div>
              <ngx-skeleton-loader
                count="1"
                appearance="line"
                [theme]="{
                  height: '10px',
                  'border-radius': '0px'
                }"
              ></ngx-skeleton-loader>
            </div>
            <div>
              <ngx-skeleton-loader
                count="1"
                appearance="line"
                [theme]="{
                  height: '20px',
                  'border-radius': '0px'
                }"
              ></ngx-skeleton-loader>
            </div>
            <div>
              <ngx-skeleton-loader
                count="1"
                appearance="line"
                [theme]="{
                  height: '30px',
                  'border-radius': '0px'
                }"
              ></ngx-skeleton-loader>
            </div>
            <div>
              <ngx-skeleton-loader
                count="1"
                appearance="line"
                [theme]="{
                  height: '40px',
                  'border-radius': '0px'
                }"
              ></ngx-skeleton-loader>
            </div>
            <div>
              <ngx-skeleton-loader
                count="1"
                appearance="line"
                [theme]="{
                  height: '50px',
                  'border-radius': '0px'
                }"
              ></ngx-skeleton-loader>
            </div>
            <div>
              <ngx-skeleton-loader
                count="1"
                appearance="line"
                [theme]="{
                  height: '60px',
                  'border-radius': '0px'
                }"
              ></ngx-skeleton-loader>
            </div>
            <div>
              <ngx-skeleton-loader
                count="1"
                appearance="line"
                [theme]="{
                  height: '70px',
                  'border-radius': '0px'
                }"
              ></ngx-skeleton-loader>
            </div>
            <div>
              <ngx-skeleton-loader
                count="1"
                appearance="line"
                [theme]="{
                  height: '80px',
                  'border-radius': '0px'
                }"
              ></ngx-skeleton-loader>
            </div>
            <div>
              <ngx-skeleton-loader
                count="1"
                appearance="line"
                [theme]="{
                  height: '90px',
                  'border-radius': '0px'
                }"
              ></ngx-skeleton-loader>
            </div>
            <div>
              <ngx-skeleton-loader
                count="1"
                appearance="line"
                [theme]="{
                  height: '100px',
                  'border-radius': '0px'
                }"
              ></ngx-skeleton-loader>
            </div>
            <div>
              <ngx-skeleton-loader
                count="1"
                appearance="line"
                [theme]="{
                  height: '110px',
                  'border-radius': '0px'
                }"
              ></ngx-skeleton-loader>
            </div>
            <div>
              <ngx-skeleton-loader
                count="1"
                appearance="line"
                [theme]="{
                  height: '120px',
                  'border-radius': '0px'
                }"
              ></ngx-skeleton-loader>
            </div>
            <div>
              <ngx-skeleton-loader
                count="1"
                appearance="line"
                [theme]="{
                  height: '130px',
                  'border-radius': '0px'
                }"
              ></ngx-skeleton-loader>
            </div>
            <div>
              <ngx-skeleton-loader
                count="1"
                appearance="line"
                [theme]="{
                  height: '140px',
                  'border-radius': '0px'
                }"
              ></ngx-skeleton-loader>
            </div>
            <div>
              <ngx-skeleton-loader
                count="1"
                appearance="line"
                [theme]="{
                  height: '150px',
                  'border-radius': '0px'
                }"
              ></ngx-skeleton-loader>
            </div>
            <div>
              <ngx-skeleton-loader
                count="1"
                appearance="line"
                [theme]="{
                  height: '160px',
                  'border-radius': '0px'
                }"
              ></ngx-skeleton-loader>
            </div>
            <div>
              <ngx-skeleton-loader
                count="1"
                appearance="line"
                [theme]="{
                  height: '170px',
                  'border-radius': '0px'
                }"
              ></ngx-skeleton-loader>
            </div>
            <div>
              <ngx-skeleton-loader
                count="1"
                appearance="line"
                [theme]="{
                  height: '180px',
                  'border-radius': '0px'
                }"
              ></ngx-skeleton-loader>
            </div>
          </div>
        </div>
      </mat-card>
      <!------------>
    </div>
  </div>
</ng-template>
