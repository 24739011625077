import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { convert } from 'src/app/utils/numberConvertion.util';

@Component({
  selector: 'app-portfolio-investment',
  templateUrl: './portfolio-investment.component.html',
  styleUrls: [
    './portfolio-investment.component.scss',
    '../insights.component.scss'
  ]
})
export class PortfolioInvestmentComponent {
  @Input() selectedVehicle: any;
  @Input() legendColors: string[] = [];
  @Input() portfolioInvestments!: any[];
  @Input() portFolioInvestmentSegments: { [key: string]: string } = {};

  @Input() selectedInitialAndFollowOnInvestments: any;
  @Input() selectedInitialInvestmentPacing: any;
  @Input() selectedFollowOnInvestmentPacing: any;
  @Input() selectedAverageHoldingPeriod: any;

  @Input() isInitialVsFollowOnUpdated$!: BehaviorSubject<boolean>;
  @Input() isInitialPacingUpdated$!: BehaviorSubject<boolean>;
  @Input() isFollowOnPacingUpdated$!: BehaviorSubject<boolean>;
  @Input() isAverageHoldingPeriodUpdated$!: BehaviorSubject<boolean>;

  @Output() viewChangeEvent = new EventEmitter<{ data: any; box: any }>();

  isOneColumnView$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.Handset, Breakpoints.TabletPortrait])
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Handset])
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  isTablet$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.Medium])
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  constructor(private breakpointObserver: BreakpointObserver) {}

  handleViewChange(data: any, box: any) {
    this.viewChangeEvent.emit({data, box});
  }

  convertCurrency(value: any) {
    return convert(value.value, value.type, this.selectedVehicle);
  }

  scrollToSection(sectionId: string) {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
