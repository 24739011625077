import { Injectable } from '@angular/core';
import { ApiEndpointsService } from '../core/services/api-endpoints.service';

@Injectable({
  providedIn: 'root'
})
export class InsightsService {
  constructor  (
    private apiEndpointsService: ApiEndpointsService) { 
  }
 
  public getFundMetrics(lpsId: string, vehicleId: string): any {
    return this.apiEndpointsService.createUrl(`lps/${lpsId}/vehicles/${vehicleId}/insights/fund-metrics`, false);
  }
  public getPortfolioInvestments(lpsId: string, vehicleId: string): any {
    return this.apiEndpointsService.createUrl(`lps/${lpsId}/vehicles/${vehicleId}/insights/portfolio-investments`, false);
  }
  public getFundInvestments(lpsId: string, vehicleId: string): any {
    return this.apiEndpointsService.createUrl(`lps/${lpsId}/vehicles/${vehicleId}/insights/fund-investments`, false);
  }
}
