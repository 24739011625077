import { map, Observable, shareReplay } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import WebViewer from '@pdftron/pdfjs-express-viewer';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent implements OnInit, AfterViewInit {
  @ViewChild('viewer', { static: false })
  viewer!: ElementRef;
  @ViewChild('drawer', { static: false })
  drawer!: ElementRef;
  wvInstance: any;
  @Input() pageNumber: number | undefined;
  @Input() filePath: string | undefined;
  @Input() fileName: any;
  @Output() handlePageChangeFromChild = new EventEmitter<string>();
  isDocumentLoaded: boolean = false;
  @Input() disableHeader: boolean = false;

  viewPortMode: boolean = false;
  innerWidth: any;

  @HostListener('window:popstate', ['$event'])
  onPopState(event: any) {
    document.querySelectorAll('mat-sidenav').forEach((el: any) => {
      el.style.transform = 'none';
      el.style.display = 'block';
    });
    if (window.innerWidth > 959 && window.innerWidth < 1280) {
      document.querySelectorAll('mat-sidenav-content').forEach((el: any) => {
        el.style.marginLeft = '70px';
      });
    }
    if (window.innerWidth >= 1280) {
      document.querySelectorAll('mat-sidenav-content').forEach((el: any) => {
        el.style.marginLeft = '200px';
      });
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth && this.viewPortMode) {
      document.querySelectorAll('mat-sidenav').forEach((el: any) => {
        el.style.transform = 'translate3d(-100%, 0, 0)';
        el.style.display = 'none';
      });
    } else {
      document.querySelectorAll('mat-sidenav').forEach((el: any) => {
        el.style.transform = 'none';
        el.style.display = 'block';
      });
      if (window.innerWidth > 959 && window.innerWidth < 1280) {
        document.querySelectorAll('mat-sidenav-content').forEach((el: any) => {
          el.style.marginLeft = '70px';
        });
      }
      if (window.innerWidth >= 1280) {
        document.querySelectorAll('mat-sidenav-content').forEach((el: any) => {
          el.style.marginLeft = '200px';
        });
      }
    }
  }

  handlePageChange(pageNumber: any): any {
    this.handlePageChangeFromChild.emit(pageNumber);
  }

  isTablet$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.Medium])
    .pipe(
      map((result) => {
        return result.matches;
      }),
      shareReplay()
    );

  handleViewPortMode(): void {
    this.viewPortMode = !this.viewPortMode;
    if (this.viewPortMode) {
      document.querySelectorAll('mat-sidenav').forEach((el: any) => {
        el.style.transform = 'translate3d(-100%, 0, 0)';
      });
      document.querySelectorAll('mat-sidenav-content').forEach((el: any) => {
        el.style.zIndex = '0';
        el.style.marginLeft = '0px';
      });
    } else {
      document.querySelectorAll('mat-sidenav').forEach((el: any) => {
        el.style.transform = 'none';
        el.style.display = 'block';
      });
      if (window.innerWidth > 959 && window.innerWidth < 1280) {
        document.querySelectorAll('mat-sidenav-content').forEach((el: any) => {
          el.style.marginLeft = '70px';
        });
      }
      if (window.innerWidth >= 1280) {
        document.querySelectorAll('mat-sidenav-content').forEach((el: any) => {
          el.style.marginLeft = '200px';
        });
      }
    }
    this.cdr.detectChanges();
  }
  languageCode: string = '';

  zoomLevel!: string;

  getPdfLicenseKey(hostname: string): string {
    if (hostname === 'investors.join.capital') {
      return 'Ivgki65xjoF33iJykN88';
    } else if (hostname === 'investors.robincap.com') {
      return 't9a3y8QIhp9zCvpCaNzh';
    } else if (hostname === 'investors.interfacecap.com') {
      return 'miV026R1SE1ZJmFGa7bl';
    } else if (hostname === 'investors.bfc.vc') {
      return 'MJfZ17ujy7MAZjHQw6fl';
    } else if (hostname === 'investors.w3.fund') {
      return 'fyz8P9BHgZSQvAjtwlf7';
    } else if (hostname === 'investors.vireo.vc') {
      return 'kzEov81RuVAprxQEEXPf';
    } else if (hostname === 'hic-investors.redstone.vc') {
      return 'anHW55CYTy3fxtHXGCXg';
    } else if (hostname === 'investors.vanagon.vc') {
      return '9VzJh6bNMJ8DBWdlDYAF';
    } else if (hostname === 'investors.4p.capital') {
      return 'ixWtG9zjeAp3oMfvogpr';
    } else if (hostname === 'investors.alstin.capital') {
      return '78ZN77OUIeKnuLS4LuCE';
    } else if (hostname === 'investors.seedandspeed.com') {
      return '8bNLH7CbgC6NqyvBJ0D4';
    } else if (hostname === 'investors.identity.vc') {
      return '1jsxb7ElytWzP5ZMrHqM';
    } else if (hostname === 'investors.matterwave.vc') {
      return 'iNhay92nRSBI7ngrSp3V';
    } else if (hostname === 'investors.altitude-vc.com') {
      return 'TdCuJ8o6c188TrbWAryh';
    } else if (hostname === 'investors.caesar.vc') {
      return 'AUWRf8NdV8sm3llnWMXk';
    } else if (hostname === 'investors.nucleus-capital.com') {
      return '1xSZy7sEWVoQYHG4WJRJ';
    } else if (hostname === 'investors.stellavent.de') {
      return 'OGGjj7xdRKbQMetAdEgB';
    } else if (hostname === 'investors.ecapital.vc') {
      return 'tlCbj7OKym6QErfYdcNq';
    } else {
      // Default issuer
      return environment.pdfjsExpressViewerLicenseKey;
    }
  }

  ngAfterViewInit(): void {
    const domainName = window.location.hostname;
    const PdfLicenseKey = this.getPdfLicenseKey(domainName);
    WebViewer(
      {
        css: '../../../../../../assets/custom.css',
        path: '../../../assets/lib',
        licenseKey: PdfLicenseKey
      },
      this.viewer.nativeElement
    ).then((instance: any) => {
      const iframeDoc = instance.UI.iframeWindow.document;
      const UIEvents = instance.UI.Events;
      instance.UI.setLanguage(this.languageCode);
      instance.UI.disableElements(['downloadButton']);
      instance.UI.disableElements(['themeChangeButton']);
      instance.UI.disableElements(['languageButton']);
      if (this.disableHeader) {
        instance.UI.disableElements(['header']);
      }
      instance.UI.setHeaderItems(
        (header: {
          getItems: () => any;
          update: (arg0: any) => void;
          getHeader: (arg0: string) => {
            img: string;
            index: number;
            type: string;
            title: string;
            element: string;
            onClick: () => void;
          }[];
        }) => {
          const items = header.getItems();
          header.getHeader('default').push({
            img: `<svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 16H18V9H20V17C20 17.2652 19.8946 17.5196 19.7071 17.7071C19.5196 17.8946 19.2652 18 19 18H1C0.734784 18 0.48043 17.8946 0.292893 17.7071C0.105357 17.5196 0 17.2652 0 17V9H2V16ZM12 6H17L10 13L3 6H8V0H12V6Z" fill="#868E96"/></svg>`,
            index: -1,
            type: 'actionButton',
            title: 'Download',
            element: 'downloadButton',
            onClick: () => {
              instance.UI.downloadPdf();
            }
          });
          const itemToMove = items.splice(13, 1)[0]; // Remove the item at index 13
          itemToMove.index = 9; // Set the new index value
          items.splice(9, 0, itemToMove); // Insert the item at index 9
          header.update(items);
        }
      );

      instance.UI.setHeaderItems((header: any) => {
        const items = header.getItems();
        header.getHeader('default').push({
          type: 'statefulButton',
          initialState: 'Maximize',
          states: {
            Maximize: {
              img: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19 0C19.5523 0 20 0.44772 20 1V9H18V2H2V18H8V20H1C0.44772 20 0 19.5523 0 19V1C0 0.44772 0.44772 0 1 0H19ZM19 11C19.5523 11 20 11.4477 20 12V19C20 19.5523 19.5523 20 19 20H11C10.4477 20 10 19.5523 10 19V12C10 11.4477 10.4477 11 11 11H19ZM9.5 4L7.45711 6.04311L9.7071 8.2929L8.2929 9.7071L6.04311 7.4571L4 9.5V4H9.5Z" fill="#868E96"/></svg>',
              title: 'Maximize',
              onClick: (update: any) => {
                this.handleViewPortMode();
                update('Minimize');
              }
            },
            Minimize: {
              img: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19 0C19.5523 0 20 0.44772 20 1V9H18V2H2V18H8V20H1C0.44772 20 0 19.5523 0 19V1C0 0.44772 0.44772 0 1 0H19ZM19 11C19.5523 11 20 11.4477 20 12V19C20 19.5523 19.5523 20 19 20H11C10.4477 20 10 19.5523 10 19V12C10 11.4477 10.4477 11 11 11H19Z" fill="#868E96"/><path d="M6.24992 7.66392L4.20703 9.70703L9.70703 9.70703L9.70703 4.20703L7.66392 6.24993L5.41413 3.99993L3.99993 5.41413L6.24992 7.66392Z" fill="#868E96"/></svg>',
              title: 'Minimize',
              onClick: (update: any) => {
                this.handleViewPortMode();
                update('Maximize');
              }
            }
          },
          mount: () => { },
          unmoun: () => { },
          dataElement: 'viewPortButton'
        });
        const itemToMove = items.splice(14, 1)[0]; // Remove the item at index 13
        itemToMove.index = 9; // Set the new index value
        items.splice(9, 0, itemToMove); // Insert the item at index 9
        header.update(items);
      });

      instance.UI.addEventListener(
        UIEvents.FULLSCREEN_MODE_TOGGLED,
        (event: any) => {
          if (event.detail.isInFullscreen) {
            instance.UI.disableElements(['viewPortButton']);
          } else {
            instance.UI.enableElements(['viewPortButton']);
          }
        }
      );

      instance.UI.loadDocument(this.filePath, { filename: this.fileName });
      this.isDocumentLoaded = true;
      this.wvInstance = instance;
      const { Core } = instance;

      // adding an event listener for when a document is loaded
      Core.documentViewer.addEventListener('documentLoaded', () => {
        this.wvDocumentLoadedHandler();
      });

      Core.documentViewer.addEventListener(
        'pageComplete',
        (pageNumber: any, canvas: any) => {
          // here it's guaranteed that page {pageNumber} is fully rendered
          // you can get or set pixels on the canvas, etc
        }
      );

      // adding an event listener for when the page number has changed
      Core.documentViewer.addEventListener(
        'pageNumberUpdated',
        (pageNumber: any) => {
          this.handlePageChange(pageNumber);
        }
      );
    });
  }
  ngOnChanges() {
    this.wvDocumentLoadedHandler();
    this.wvDocumentLoadedHandler = this.wvDocumentLoadedHandler.bind(this);
  }

  ngOnInit() {
    const { languageCode } = JSON.parse(this.userService.getUserConfig());
    this.languageCode = languageCode;
    this.pageNumber ? Math.abs(this.pageNumber) : 1;
    this.wvDocumentLoadedHandler();
    this.wvDocumentLoadedHandler = this.wvDocumentLoadedHandler.bind(this);
  }

  constructor(
    private userService: UserService,
    private cdr: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver
  ) {}

  async wvDocumentLoadedHandler(): Promise<void> {
    const instance = await this.wvInstance;
    if (!instance) {
      return;
    }
    await instance.setZoomLevel('100%');
    const { Core, UI } = instance;

    if (this.pageNumber) {
      Core.documentViewer.setCurrentPage(Math.abs(this.pageNumber));
    }
  }
  downloadPdf(): void {
    if (this.wvInstance) {
      this.wvInstance.UI.downloadPdf();
    }
  }
}
